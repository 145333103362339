<div class="container mt-5 pt-5">

    
    <div class="h4">ABOUT US</div>
    <p>Essential Artisans is a Progressive Web App, which connects homeowners/corporate companies with trained/verified/qualified professional artisans to carry out home and office improvement, maintenance, and remodeling.</p>
    
    <p>The App is an innovative application with a hassle-free solution to the common problem of finding good/verified artisans/handymen and professionals around us to get tasks done.</p>
    
    <p>The App connects you from wherever you are in Nigeria with the needed handymen in less than thirty minutes at a click of a button on your mobile phone or desktop. On Essential Express, you will find well-trained professional artisans/handymen for your home and/or office quick fixes and our charges are very competitive.</p>
    
    <p>The App also gives vendors an opportunity to advertise their items for free, so as to increase their market coverage.</p>
    
    <p>On Essential Artisans App, you can also find artisans and vendors for different sizes of tasks varying from minor repairs/tasks to major jobs repairs/tasks.</p>
    
    <p>Essential Artisans App features hundreds of artisans from different industries across the 36 states of Nigeria and the FCT.</p>
    
    <p>Are you tired of having shoddy jobs done by incompetent handymen? Do you need handymen for quick fixes any time of the day? Then your best bet is Essential Artisans, the web App that connects you with competent and professional artisans by bringing an excellent service to your doorstep.</p>
    
    
    
    
    <div class="h4" >WHAT CAN YOU DO WITH ESSENTIAL ARTISANS?</div>
<p>    Essential Artisans holds qualified artisans within the following categories.
</p>
    <ul>
    <li>	Carpentry
    </li><li>	Air-Conditioning Repairs/Maintenance
    </li><li>	Electrical works
    </li><li>	Plumbing</li>
</ul>
    <p><span class="fw-bold">CARPENTRY:</span> We provide end to end Carpentry services for Individual Residential repairs/projects or Company/Organizational repairs/projects. Architectural Services, Material Vendors to Final Handover in a smooth and timely manner.</p>
    <p><span class="fw-bold">AIR-CONDITIONING:</span> Get your home/office AC installation/service and/or repair done by verified AC technicians/trusted professionals who have their backgrounds verified and are skilled professionals.</p>
    <p><span class="fw-bold">ELECTRICAL WORKS:</span> Get your electrical appliances mounted/repaired by our experts who are professionally trained & verified. Experts in Washing machines, Water-Purifier repair & many more.</p>
    Servicing, Repair, Installation & Uninstallation.
    
    <p><span class="fw-bold">PLUMBING:</span> Get your home/office plumbing services done by verified plumbers/trusted professionals who have their backgrounds verified and are skilled professionals.</p>
    


</div>
