export class orderModel{
    // artisanId:=0;
    id:number= 0;
    name: string='';
    propertyAddress: string='';
    issue: string='';
    inspectionDate!:'';
    inspectionTime!:''
    mobileNumber:string ='';
    AltNumber:string ='';
    artisanEmail:string ='';
    file!: File;
    artisanId:number=0;
    
isApproved:boolean= false;
    fileSource: string='';
    orderId:number= 0;
  }