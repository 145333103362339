<div class="content mx-5">
    <h2>TERMS OF SERVICE</h2><br>
<div> - Received Awaiting Request – 1hr </div><br>


<div>- Assigned Awaiting Inspection – 24hrs Maximum</div><br>


<div>- Inspected Awaiting Quotation – 12hrs Maximum</div><br>


<div>- Quotation Awaiting Acceptance – 24hrs  </div><br>


<div>- Acceptance Awaiting Execution – 24hrs Maximum to mobilize</div><br>

<div>- Completed Awaiting Closing – 48hrs Maximum. Any job assessed to require longer than 48hrs are to be assessed by the administrator</div><br>

<div>- Closed by Customer – 72hrs Maximum. Any completed job not closed by customer within this time frame will be automatically closed and considered to have been satisfactorily completed. </div><br>

<div>- On-Hold – 72hrs. Customers can decide to put execution on hold through the administrator for a  maximum period of 72hrs after payment due to absence. Jobs are automatically cancelled thereafter. </div><br>

- Overdue 
<div>Note: Overdue jobs are those that have not been closed out within 72hrs from when customer accepted quote. All overdue jobs must be automatically escalated to the Administrator</div>

</div>
