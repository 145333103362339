
<!-- Footer-->
<footer class="container-fluid bg-dark text-light ">
    <div class="container">
    <div class="row no-gutters">
    <div class="col-md-4 mb-md-0 d-flex">
    <div class=" w-100 py-3">
    <div class="con-prtext-primary w-100 text-center">
    <div class="icon d-flex align-items-center justify-content-center">
    <span class="bi bi-telephone-fill text-primary "></span>
    </div>
    <div class="text">
        
    <span>(+234) 8077780444</span>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-4 mb-md-0  d-flex">
    <div class="con con-2 w-100 py-3">
    <div class="con-prtext-primary w-100 text-center">
    <div class="icon d-flex align-items-center justify-content-center">
    <span class="bi bi-envelope-plus-fill text-primary"></span>
    </div>
    <div class="text">
    <span>support@essentialartisans.com</span>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-4 mb-md-0  d-flex">
    <div class="con con-3 w-100 py-3">
    <div class="con-prtext-primary w-100 text-center">
    <div class="icon d-flex align-items-center justify-content-center">
    <span class="bi bi-geo-alt text-primary"></span>
    </div>
    <div class="text">
    <span>22 Sikiru Alade, Lekki, Lagos</span>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="row">
    <div class="col-12 col-md-8  ">
    <div class="row d-flex  align-items-center ">
    <div class=" col-md-4 col-12 mb-md-0 mb-4 mt-3 mt-md-0 d-flex align-items-center justify-content-center">
    <h2 class="footer-heading cursor" routerLink="term of service">Terms of service</h2>
  
    </div>
    <div class="col-md-4 col-12 mb-md-0 mb-4 d-flex align-items-center justify-content-center">
    <h2 class="footer-heading cursor" routerLink="/ourpolicy">Privacy Policy</h2>
    <ul class="list-unstyled">
  
    </ul>
    </div>
    
    
    </div>
    </div>
    <div class="col-md-4 mb-md-0 mb-4 ">
    <h2 class="footer-heading ">Subscribe</h2>
    <form action="#" class="subscribe-form">
    <div class="form-group mb-3 d-flex">
    <input type="email" name="email" class="form-control rounded-left" placeholder="Enter email address">
    <button type="submit" class="btn btn-info rounded ms-1">Subscribe</button>
    </div>
    <span class="subheading mt-5">Get first hand updates in your mailbox</span>
    </form>
    </div>
    </div>
    <div class="row mt-2 pt-4 border-top">
    <div class="col-md-6 col-lg-8 mb-md-0 mb-4">
    <p class="copyright mb-0">
    Copyright  &copy;2022 All rights reserved. 
    </p>
    </div>
    <div class="col-md-6 col-lg-4 text-md-right d-flex ">
    <ul class="ftco-footer-social p-0 d-flex list-unstyled">
    <li class="ftco-animate"><a  data-toggle="tooltip" data-placement="top" title="Twitter"><span class="bi bi-twitter me-3"></span></a></li>
    <li class="ftco-animate"><a  data-toggle="tooltip" data-placement="top" title="Facebook"><span class="bi bi-facebook me-3"></span></a></li>
    <li class="ftco-animate"><a  data-toggle="tooltip" data-placement="top" title="Instagram"><span class="bi bi-instagram me-3"></span></a></li>
    </ul>
    </div>
    </div>
    </div>
    </footer>