
  <div class="m-3 d-flex justify-content-between">
    <!-- <button  type="button" (click)=" clickAddServiceCategory()" data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn btn-success">Add service category</button> -->
    <div>
      <button type="button" class="btn btn-info me-2" *ngIf="!hidden" (click)="openModal(template); clickAddServiceCategory()">
        Create Service Categories
      </button>
      <button type="button" class="btn btn-info me-2" *ngIf="!hidden" (click)="openModal(template2); clickAddSubServiceCategory()">
        Create Sub-Category
      </button>
    </div>

<div class="d-flex">
  <input type="text" class="me-3" *ngIf="hidden" [(ngModel)]="this.feeForm.value.inspectionFee">

  <button class="btn btn-success me-2" *ngIf="!hidden" (click)="changeFee()">Change Inspection Fee</button>
  <button class="btn btn-danger me-2" *ngIf="hidden" (click)="changeFee()">Cancel</button>
  <button class="btn btn-info me-2" *ngIf="hidden" (click)="changeInspectionFee()"  >Change Fee</button>

</div>  
</div>

<table class="table ">
    <thead>
      <tr>
        <!-- <th scope="col">#</th> -->
        <th scope="col-8">First</th>
        <!-- <th scope="col">Last</th> -->
        <th scope="col-4">Handle</th>
      </tr>
    </thead>
    <tbody *ngFor="let row of serviceData">
    
      
      <tr class="d-flex justify-content-between">
        <!-- <th scope="row">3</th> -->
        <td colspan="2">{{row.name}}</td>
        <td>
          <button  type="button"   class="btn btn-success me-2" (click)="openModal(template);onEdit(row)">Update</button>

  <button (click)="deleteServiceCategory(row)" class="btn btn-danger"> Delete</button>
      </td>
        
      </tr>
    </tbody>
  </table>
<!-- Button trigger modal -->

  <!-- Modal -->
   <!-- Modal -->
   <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">service category Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

            <!-- modal form -->
  <div *ngIf="showAddService">
    <form [formGroup]="formValue"  >
      <div class="mb-3" >
        <label for="exampleInputEmail1" class="form-label">Service category Name</label>
        <input formControlName="name" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
        <!-- <input formControlName="name" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
        
      </div>
      </form>
  </div>
                <div *ngIf="showUpdate">
                  <form  [formGroup]="updateFormValue" (ngSubmit)="updateServiceCategory(this.updateFormValue.value)">
                    <div class="mb-3" >
                      <label for="exampleInputEmail1" class="form-label">Service category Name</label>
                      <input formControlName="oldName" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                      <!-- <input formControloldName="oldName" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
                      
                    </div>
                    <div class="mb-3" >
                      <label for="exampleInputEmail1" class="form-label">Service category Name</label>
                      <input formControlName="newName" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                      <!-- <input formControlnewName="name" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
                      
                    </div>
                    <button     type="submit"  class="btn btn-primary">Update</button>
                  </form>
                </div>


        </div>
        <div class="modal-footer">
          <button type="button" id ="cancel" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button *ngIf="showAddService" type="button" (click)="postServiceCategory(this.formValue.value)" class="btn btn-primary">Add Category</button>
          <!-- <button *ngIf="showUpdate"    type="button" (click)="updateServiceCategory(this.updateFormValue.value)" class="btn btn-primary">Update</button> -->
          <!-- <button *ngIf="showUpdate" type="button" (click)="updateServiceCategory()" class="btn btn-primary">Update</button> -->
        </div>
      </div>
    </div>
  </div> 

  <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Service Category Details</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <!-- modal form -->
<div *ngIf="showAddService">
<form [formGroup]="formValue"  >
<div class="mb-3" >
  <label for="exampleInputEmail1" class="form-label">Service category Name</label>
  <input formControlName="Name" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
  <!-- <input formControlName="name" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
  
</div>
<label class="btn btn-default">
  <input type="file" (change)="handleFileInput2($event)">

</label>
</form>
</div>
          <div *ngIf="showUpdate">
            <form  [formGroup]="updateFormValue" (ngSubmit)="updateServiceCategory(this.updateFormValue.value)">
              <div class="mb-3" >
                <label for="exampleInputText" class="form-label">Old Service category Name</label>
                <input formControlName="oldName" type="text" class="form-control" id="exampleInputText" [(ngModel)]="this.servicecategoryModelObj.name"/>
                <!-- <input formControloldName="oldName" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
                
              </div>
              <div class="mb-3" >
                <label for="exampleInputText" class="form-label"> New Service category Name</label>
                <input formControlName="newName" type="text" class="form-control" id="exampleInputText"/>
                <!-- <input formControlnewName="name" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
                
              </div>
              <button     type="submit"  class="btn btn-info">Update</button>
            </form>
          </div>


  </div>
  <div class="modal-footer">
    <button type="button" id ="cancel" class="btn btn-secondary" (click)="modalRef?.hide()">Close</button>
    <button *ngIf="showAddService" type="button" (click)="postServiceCategory(this.formValue.value)" class="btn btn-info">Add Category</button>
    <!-- <button *ngIf="showUpdate"    type="button" (click)="updateServiceCategory(this.updateFormValue.value)" class="btn btn-primary">Update</button> -->
    <!-- <button *ngIf="showUpdate" type="button" (click)="updateServiceCategory()" class="btn btn-primary">Update</button> -->
  </div>
    
  </ng-template>


  <!-- create sub category -->
  <ng-template #template2>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Service Sub-Category Details</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <!-- modal form -->
<div *ngIf="showAddService">
<form [formGroup]="subCategoryFormValue"  >
<div class="mb-3" >
  <label for="exampleInputEmail1" class="form-label">Sub Category Name</label>
  <input formControlName="Name" type="text" class="form-control" />
  <!-- <input formControlName="name" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
  
</div>
<div class="mb-3">
  <label for="exampleInputEmail1" class="form-label">Select Category</label>

  <select class="form-select" aria-label="Default select example " formControlName="ServiceCategoryId">
                    <option >Select Estate</option>
                    <option *ngFor="let data of categoryData" [value]="data.categoryId" >{{data.name}}</option>
                    
                  </select>

</div>
<div>
  <label class="btn btn-default">Select File</label>
 
<input type="file" (change)="handleFileInput($event)">
 

</div>

</form>
</div>
        


  </div>
  <div class="modal-footer">
    <button type="button" id ="cancel" class="btn btn-secondary" (click)="modalRef?.hide()">Close</button>
    <button *ngIf="showAddService" type="button" (click)="submitForm(this.subCategoryFormValue.value)" class="btn btn-info">Add Category</button>
    <!-- <button *ngIf="showUpdate"    type="button" (click)="updateServiceCategory(this.updateFormValue.value)" class="btn btn-primary">Update</button> -->
    <!-- <button *ngIf="showUpdate" type="button" (click)="updateServiceCategory()" class="btn btn-primary">Update</button> -->
  </div>
    
  </ng-template>

  