import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule } from 'angular-highcharts';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxEchartsModule } from 'ngx-echarts';
// import { AlertModule } from 'ngx-alerts';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import {MatMenuModule} from '@angular/material/menu';
import { ModalModule } from 'ngx-bootstrap/modal';
import {MatSnackBarModule} from  '@angular/material/snack-bar'
// import {MatDialogModule} from '@angular/material/dialog';




import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/auth/login/login.component';
import { SignupComponent } from './components/auth/signup/signup.component';

import { FooterComponent } from './components/common/footer/footer.component';
// import { AboutusComponent } from './components/aboutus/aboutus.component';
import { PrivacyComponent } from './components/common/privacy/privacy.component';
import { ZippyComponent } from './components/zippy/zippy.component';
import { ContactusComponent } from './components/common/contactus/contactus.component';
import { PolicyComponent } from './components/common/policy/policy.component';


import { RegisterComponent } from './components/auth/register/register.component';
import {
  ErrorStateMatcher,
  MatNativeDateModule,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';

import { ForgetpasswordComponent } from './components/forgetpassword/forgetpassword.component';
import { EmailComponent } from './components/email/email.component';
import { UserprofileComponent } from './components/customer/userprofile/userprofile.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ArtisanprofileComponent } from './components/artisans/artisanprofile/artisanprofile.component';

import { AdminpageComponent } from './components/admin/adminpage/adminpage.component';
import { PagenotfoundComponent } from './components/common/pagenotfound/pagenotfound.component';
import { ForbiddenComponent } from './components/common/forbidden/forbidden.component';
import { AllartisanComponent } from './components/allartisan/allartisan.component';
import { AlltransactionsComponent } from './components/admin/alltransactions/alltransactions.component';
import { SignuprouteComponent } from './components/auth/signuproute/signuproute.component';
import { OfflineComponent } from './components/offline/offline.component';
// import { authInterceptorProviders } from '../_helpers/auth.interceptor';

// import { AuthGuard } from './service/auth-guard';
import { AuthInterceptor} from 'src/_helpers/auth.interceptor';
import { AuthGuard } from './shared/auth.guard';
import { RoleGuard } from './shared/role.guard';
import { FilterPipe } from './pipes/filter.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { ArtisanbyidComponent } from './components/artisanbyid/artisanbyid.component';
import { ServicecategoryComponent } from './components/admin/servicecategory/servicecategory.component';
import { AdminartisanbyidComponent } from './components/adminartisanbyid/adminartisanbyid.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { ToastrModule } from 'ngx-toastr';
import { AvatarModule } from 'ngx-avatar';
import { EmptycartComponent } from './components/emptycart/emptycart.component';
import { HelpComponent } from './components/common/help/help.component';
import { ArtisantransactionsComponent } from './components/artisans/artisantransactions/artisantransactions.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import {MatListModule} from '@angular/material/list'
import { MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Ng2OrderModule } from 'ng2-order-pipe';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { PopoverModule }
    from 'ngx-bootstrap/popover';
    import { TooltipModule  } from 'ngx-bootstrap/tooltip';

// import { DatePipe } from './pipes/date.pipe';
import { PaymentComponent } from './components/customer/payment/payment.component';
import { NgxHttpLoaderModule } from 'ngx-http-loader';
import { AboutusComponent } from './components/common/aboutus/aboutus.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ResetpasswordComponent } from './components/auth/resetpassword/resetpassword.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CategoriesComponent } from './components/categories/categories.component';
// import { EcommerceComponent } from './ecommerce/ecommerce.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { BsDropdownModule,BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { InvoiceComponent } from './invoice/invoice.component';


// import { AuthTokenInterceptors } from 'src/_helpers/AuthTokenInterceptors';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    LoginComponent,
    SignupComponent,

    FooterComponent,
    AboutusComponent,
    PrivacyComponent,

    ZippyComponent,
    ContactusComponent,
    PolicyComponent,
   
    OfflineComponent,

    RegisterComponent,

    ForgetpasswordComponent,
    EmailComponent,
    // UserprofileComponent,
    ArtisanprofileComponent,
    // AdminpageComponent,
  
    PagenotfoundComponent,
    ForbiddenComponent,
    AllartisanComponent,
    // AlltransactionsComponent,
    SignuprouteComponent,
    ResetpasswordComponent,
    
    // FilterPipe,
    SortPipe,
    ArtisanbyidComponent,
    // ServicecategoryComponent,
    AdminartisanbyidComponent,
    EmptycartComponent,
    HelpComponent,
    ArtisantransactionsComponent,
    // DatePipe,
    // PaymentComponent,
    CategoriesComponent,
    InvoiceComponent,
    // EcommerceComponent,
    
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    

    // CarouselModule,
    MatSnackBarModule,
    NgbModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    Ng2SearchPipeModule,
    MatExpansionModule,

    HighchartsChartModule,
    ChartModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    NgxPaginationModule,
    NgxStarRatingModule,
    AvatarModule,
    FlexLayoutModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    Ng2OrderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BsDropdownModule.forRoot(),

    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    NgxHttpLoaderModule.forRoot(),


    // MatDialogModule,
    // MatDividerModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(
      {
        timeOut: 10000,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }
    ),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    

    RouterModule.forRoot(
      [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
        {
          path: 'signin',
          component: LoginComponent,
          data: {
            userType: 'non-logged-in',
          }
        },

        { path: '', component: DashboardComponent, },
        { path: 'signup', component: SignupComponent,   data: {
          userType: 'non-logged-in',
        }, },
        { path: 'aboutus', component: AboutusComponent },
        { path: 'contactus', component: ContactusComponent },
        { path: 'ourpolicy', component: PolicyComponent },
        {path: "service" , component: ServicecategoryComponent },
        
        { path: 'register', component: RegisterComponent },
        // { path: 'empty', component: EmptycartComponent },
        { path: 'helpcenter', component: HelpComponent },

        // { path: 'userprofile', component: UserprofileComponent, canActivate:[RoleGuard] },
        // { path: 'artisanprofile', component: ArtisanprofileComponent, canActivate:[RoleGuard]  },
        { path: 'forgetpassword', component: ForgetpasswordComponent },
        
        { path: 'admin', component: AdminpageComponent, canActivate:[AuthGuard]  },
        { path: 'forbidden', component: ForbiddenComponent },
        {
          path: 'available artisan',
          component: AllartisanComponent,
          canActivate:[AuthGuard],
        },
        { path: 'categories', component: CategoriesComponent },
        // { path: 'alltransactions', component: AlltransactionsComponent },
        { path: 'confirmemail', component: EmailComponent },
        { path: 'checkemail', component: SignuprouteComponent },
        { path: 'passwordreset', component: ResetpasswordComponent },
        // { path: 'passwordreset', component: ResetpasswordComponent },
        { path: 'artisanbyid', component: AdminartisanbyidComponent },
        { path: 'term of service', component: PrivacyComponent },
        { path: 'invoice', component: InvoiceComponent },//invoice for testing
        { path: 'payment', component: PaymentComponent },
        {
          path: 'artisanprofile',
          loadChildren: () => import('./components/artisans/artisanprofile/artisans.module')
            .then(m => m.ArtisansModule),
            canActivate:[RoleGuard]
        },
        {
          path: 'User',
          loadChildren: () => import('./components/customer/customer.module')
            .then(m => m.CustomerModule),
            canActivate:[RoleGuard]
        },
        {
          path: 'Admin',
          loadChildren: () => import('./components/admin/admin.module')
            .then(m => m.AdminModule),
        },
        {
          path: 'market place',
          loadChildren: () => import('./ecommerce/ecommerce.module')
            .then(m => m.EcommerceModule),
        },
        // { path: 'offline', component: OfflineComponent },
        // { path: 'payment', component: PaymentComponent },
        { path: '404', component: PagenotfoundComponent },
        { path: '**', component: PagenotfoundComponent },

       
      ],
      { scrollPositionRestoration: 'enabled' }
    ),
      AppRoutingModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  //   ServiceWorkerModule.register("ngsw-worker.js", {
  //     enabled: environment.production,
  //     registrationStrategy: "registerImmediately"
  // }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })

  ],
  providers: [
    {provide: LocationStrategy, useClass:PathLocationStrategy},

    { provide: HTTP_INTERCEPTORS,  useClass: AuthInterceptor, multi:true },
    
    
  ],
 
  bootstrap: [AppComponent],
})
export class AppModule {}
