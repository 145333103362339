<div class="container mt-5 pt-5  d-flex align-items-center justify-content-center text-muted" style="height: 80vh;">
    <div id="mycheck" class="check ">
        <div class="check-dialog check-confirm">
            <div class="check-content">
                <div class="check-header">
                    <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                    </div>				
                    <h4 class="check-title w-100">Awesome!</h4>	
                </div>
                <div class="check-body">
                    <p class="text-center h5">Your Payment was Successful....</p>
                </div>
                <div class="check-footer">
                    <button class="btn btn-success btn-block mt-3" data-dismiss="check" routerLink="/User/Wallet">Back To Profile</button>
                </div>
            </div>
        </div>
    </div> 
   
</div>
