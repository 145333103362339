<div class="container-fluid">
  <section class="row pt-2">
    <div class="row d-flex justify-content-around">
      <div class="row text-light">
        <div class="col-xl-3 col-lg-6 col-md-6 col-6 justify-content-center">
          <!-- Card -->
          <div class="card mb-4">
            <!-- Card body -->
            <div class="card-body col1 rounded justify-content-center">
              <div
                class="d-flex align-items-center justify-content-between mb-3 lh-1"
              >
                <div>
                  <span class="fs-6 text-uppercase fw-semi-bold"
                    >Transactions</span
                  >
                  <!-- <i class="fa-solid fa-eye"></i>
                      <i class="fa-duotone fa-eye"></i> -->
                </div>
                <div>
                  <span class="bi bi-shopping-bag fs-3 text-primary"></span>
                </div>
              </div>
              <h2 class="fw-bold mb-1">{{ totalLength }}</h2>
              <h2 class="fw-bold mb-1" *ngIf="!AllOrderData ">0</h2>

            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-6">
          <!-- Card -->
          <div class="card mb-4">
            <!-- Card body -->
            <div class="card-body rounded col2">
              <div
                class="d-flex align-items-center justify-content-between mb-3 lh-1"
              >
                <div>
                  <!-- <i class="fa-solid fa-face-smile"></i> -->
                  <span class="fs-6 text-uppercase fw-semi-bold">Pending</span>
                </div>
                <div>
                  <!-- <span class="bi bi-book-open fs-3 text-primary"></span> -->
                </div>
                
              </div>
              <h2 class="fw-bold mb-1">{{AllpendingLength}}</h2>
              <h2 class="fw-bold mb-1" *ngIf="!AllpendingData">0</h2>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-6">
          <!-- Card -->
          <div class="card mb-4">
            <!-- Card body -->
            <div class="card-body col3 rounded">
              <div
                class="d-flex align-items-center justify-content-between mb-3 lh-1"
              >
                <div>
                  <!-- <i class="fa-solid fa-heart"></i> -->
                  <span class="fs-6 text-uppercase fw-semi-bold"
                    >Completed</span
                  >
                </div>
              
              </div>
              <h2 class="fw-bold mb-1">{{completeOrderLength}}</h2>
              <h2 class="fw-bold mb-1" *ngIf="!completeOrderData">0</h2>
              
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-6">
          <!-- Card -->
          <div class="card mb-4">
            <!-- Card body -->
            <div class="card-body col4 rounded">
              <div
                class="d-flex align-items-center justify-content-between mb-3 lh-1"
              >
                <div>
                  <!-- <i class="fa-solid fa-users-viewfinder"></i> -->
                  <span class="fs-6 text-uppercase fw-semi-bold">Canceled</span>
                </div>
                <div>
                  <span class="bi bi-users fs-3 text-primary"></span>
                </div>
              </div>
              <h2 class="fw-bold mb-1">{{cancelOrderLength}}</h2>
              <h2 class="fw-bold mb-1" *ngIf="!cancelOrderData">0</h2>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <nav class="navbar navbar-light bg-light">
   
    <div class="container-fluid">
      <div class="navbar-brand w-100" href="#">
        <div class="w-100 d-flex w-100 justify-content-between">
          <div
            [class.active]="service == 'completed'"
            class="mat cursor d-none d-lg-block p-2"
            (click)="service = 'completed'"
          >
            All Transactions
          </div>
          <div
            [class.active]="service == 'completed1'"
            class="mat cursor d-none d-lg-block p-2"
            (click)="service = 'completed1'"
            
          >
            Pending Orders
          </div>
          <div
            [class.active]="service == 'completed2'"
            class="mat cursor d-none d-lg-block p-2"
            (click)="service = 'completed2'"
          >
            Completed Orders
          </div>
          <div
            [class.active]="service == 'completed3'"
            class="mat cursor d-none d-lg-block p-2"
            (click)="service = 'completed3';"
          >
            Cancel Orders
          </div>
        </div>
      </div>
      <div
        class="navbar-toggler d-lg-none"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        ardiva-controls="offcanvasNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </div>
      <h4 class="d-lg-none">SERVICES</h4>
      <div
        class="offcanvas offcanvas-end mt-4 pt-5 w-50"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">SERVICES</h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li
              [class.active]="service == 'completed'"
              class="mat cursor  p-2"
              data-bs-dismiss="offcanvas"
            aria-label="Close"
              (click)="service = 'completed'"
            >
              All Transactions
            </li>
            <li
              [class.active]="service == 'completed1'"
              class="mat cursor p-2"
              (click)="service = 'completed1'"
              data-bs-dismiss="offcanvas"
            aria-label="Close"
            >
              Pending Orders
            </li>
            <li
              [class.active]="service == 'completed2'"
              class="mat cursor p-2"
              (click)="service = 'completed2'"
              data-bs-dismiss="offcanvas"
            aria-label="Close"
            >
              Completed Orders
            </li>
            <li
              [class.active]="service == 'completed3'"
              class="mat cursor p-2"
              (click)="service = 'completed3'"
              data-bs-dismiss="offcanvas"
            aria-label="Close"
            >
              Cancel Orders
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

  <section>
    <div [ngSwitch]="service">
      <div class="row d-flex">
        <div class="d-flex col-6">
          <div class="">
            <input
              type="date"
              [(ngModel)]="fromDate1"
              class="me-2"
              placeholder="
                dd/mm/yyyy, --:--
                "
            />
          </div>
          <div class=" ">
            <input
              type="date"
              style="margin-right: 3px"
              [(ngModel)]="toDate1"
              placeholder="
                dd/mm/yyyy, --:--
                "
            />
          </div>
          <div class=" border border-2 d-flex align-center justify-content-center" style="height: 27px; width: 30px; background-color:aliceblue" (click)="filterByDate()"><i class="bi bi-search"></i></div>

          <!-- <div class="col-md-2">
            <button class="btn btn-danger h-75" (click)="filterByDate()">
              Search
            </button>
          </div> -->
        </div>

        <div class=" col-md-3 my-2 my-md-0  ">
          <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="&#61442;  Start searching for Artisans within your location">
        </div>
      </div>

      <!-- all transaction -->
      <div *ngSwitchCase="'completed'" id="no-tables">
        
        <div *ngIf="AllOrderData">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th>Inspection Date</th>
                <th>Completion Date</th>
                <th scope="col" (click)="sort('id')">
                  Order ID&nbsp;<i class="fa fa-sort"></i>
                </th>
                <th scope="col">Issue</th>
                <th scope="col">Location</th>
                <th scope="col">Customer's Name</th>
                <th scope="col">Customer's Number</th>

                <th scope="col">Status</th>

              </tr>
            </thead>
            <tbody *ngIf="!AllOrderData.isApproved === true">
              <tr
                *ngFor="
                  let data of filteredOrderData | filter: searchText
                   
                    | paginate: { itemsPerPage: 20, currentPage: p }
                "
              >
                <td data-title="Date">{{ data.date }}</td>
                <td data-title="inspection Date">{{ data.inspectionDate }}</td>
                <td data-title="Completion Date">{{data.completionDate}}</td>
                <td data-title="Order ID">{{data.uniqueId}}</td>
                <td data-title="Issue"  title="{{data.issue}}" class="but" >{{(data.issue.length>6)? (data.issue | slice:0:7)+'...':(data.issue)}}</td>
                <td data-title="Location">{{ data.propertyAddress }}</td>
                <td data-title="Customer's Name">{{data.name}}</td>
                <td data-title="Customer's Number" *ngIf="data.action  === 3 || data.action === 2 || data.action === 4">{{data.phoneNumber}}</td>
                <td data-title="Customer's Number" *ngIf="data.action <= 1 || data.action === 5"></td>

                <td data-title="Status" *ngIf="data.action === 1">Pending</td>
                <td data-title="Status" *ngIf="data.action === 2 && !data.isOrderInvoiceApproved">Processing</td>
                <td data-title="Status" *ngIf="data.action === 2 && data.isOrderInvoiceApproved && !data.isOrderInvoicePaid">Invoice Approved</td>
                <td data-title="Status" *ngIf="data.action === 2 && data.isOrderInvoicePaid">Paid</td>
                <td data-title="Status" *ngIf="data.action === 4">Completed</td>

                      
          
                <!-- <td data-title="Status" *ngIf="data.action === 2 && data.isOrderInvoiceApproved"> Approve</td> -->
                <!-- <td data-title="Status" *ngIf="data.action === 2 && data.isOrderInvoicePaid && data.isOrderInvoiceApproved"> Paid</td> -->
                
                <td data-title="Status" *ngIf="data.action === 5">Admin Canceled</td>
                <td data-title="Status" *ngIf="data.action === 3">Canceled</td>

                <!-- <td data-title="Artisan's Name">{{data.artisanName}}</td> -->
                <td data-title="Action">
                  <!-- <button (click)="onEditOrder(data)" class="btn btn-info me-2" *ngIf="!data.isApproved" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                      Edit
                    </button> -->
                    <button class="btn btn-info me-2" (click)="openModal(template2); onClickViewOrder(data)">View Order</button>

                  <button
                    class="btn btn-info"
                    *ngIf="!data.isApproved && data.action === 1"
                    (click)="artisanAcceptOrders(data)"
                  >
                    Accept Order
                  </button>
                  <button
                    class="btn btn-danger"
                    *ngIf="!data.isApproved && data.action === 1"
                    (click)="artisanCancelOrders(data)"
                  >
                    Reject Order
                  </button>
                  <!-- <button
                    type="button"
                    class="btn btn-info"
                    (click)="openModal(template)"
                    (click)="onEdit(data)"
                    *ngIf="data.action === 2 && !data.hasInvoice
                    " 
                  >
                    Create Invoice
                  </button> -->
                  <!-- <ng-template #template3>
                    <div class="modal-body text-center">
                      <p>Kindly note the commission rate below on Artisans Service charge</p>
                      <button type="button" class="btn btn-danger me-3" (click)="decline()" >Decline</button>
                      <button type="button" class="btn btn-info" (click)=" openModal(template); onEdit(data)" >Proceed</button>

                    </div>
                  </ng-template> -->
                  <!-- <button type="button" *ngIf="data.action === 2 && !data.hasInvoice
                  "  class="btn btn-info" (click)="openModal2(template3); onEdit(data)"> Create Invoice</button> -->

                  <button type="button" *ngIf="data.action === 2 && !data.hasInvoice
                  "  class="btn btn-info" (click)="openModal(template4); onEdit(data)"> Create Invoice</button>


                  <button class="btn btn-info" *ngIf="data.action === 2 && data.hasInvoice && !data.isOrderInvoiceApproved"> Invoice Awaiting Approval</button>

                  <!-- <button *ngIf="data.isApproved">Awaiting Quote</button> -->
                  <button class="btn btn-danger" *ngIf="data.action === 5">Canceled</button>
                  <button class="btn btn-danger" *ngIf="data.action === 3">Canceled</button>
                  <button class="btn btn-success" *ngIf="data.invoiceId && data.action === 5">Invoice Created</button>
                  <button class="btn btn-success" (click)="completeOrder(data)" *ngIf="data.action === 2 && data.isOrderInvoicePaid">Complete Job</button>
                  <!-- <button class="btn btn-success" *ngIf="data.action === 1">
                    Approved
                  </button> -->
                  <!-- <button (click)="completeOrder(data)">complete order</button> -->
                  <div  *ngIf="data.action === 4">
                    <i class="bi bi-check-all text-success fa-3x"> </i>
                  </div>
                </td>

              
              </tr>

              <tr *ngIf="AllOrderData.length == 0">
                no order
              </tr>
              
            </tbody>
            
          </table>
           

          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>

        <div *ngIf="!AllOrderData">
          <app-emptycart></app-emptycart>
        </div>
      </div>

      <!-- pending transaction -->
      <div *ngSwitchCase="'completed1'" id="no-tables">
        <div *ngIf="AllpendingData">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Order ID</th>
                <th scope="col">Date</th>
                <th scope="col">Inspection Date</th>
                <th scope="col">Issue</th>
                <th scope="col">Location</th>
                <th scope="col">Status</th>
                <th scope="col">User's Name</th>
                <!-- <th scope="col">Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let data of AllpendingData | paginate: { itemsPerPage: 20, currentPage: pendingPage }">
                <td data-title="Order ID">{{ data.id}}</td>
                <td data-title="Date">{{ data.date }}</td>
                <td data-title="Inspection Date">{{data.inspectionDate}}td>
                <!-- <td data-title="Order ID">{{data.uniqueId}}/td> -->
                <td data-title="Issue" title="{{data.issue}}" class="but" >{{(data.issue.length>6)? (data.issue | slice:0:7)+'...':(data.issue)}}</td>

                <td data-title="Location">{{ data.propertyAddress }}</td>
                <td data-title="Status">Pending</td>
                <td data-title="User's Name">{{data.name}}</td>

                <td data-title="Action">
                
                  <button class="btn btn-info" *ngIf="!data.isApproved && data.action === 1"
                  (click)="artisanAcceptOrders(data)">
                    Accept Order
                  </button>
                  <button
                  class="btn btn-cancel"
                  *ngIf="!data.isApproved && data.action === 1"
                  (click)="artisanCancelOrders(data)"
                >
                  Reject Order
                </button>
                <!-- <button
                type="button"
                class="btn btn-info"
                (click)="openModal(template)"
                (click)="onEdit(data)"
                *ngIf="data.action === 2 && !data.hasInvoice
                " 
              >
                Create Invoice
              </button> -->
              <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Launch static backdrop modal
              </button> -->
                </td>

               
               

              </tr>


            </tbody>
          </table>
          <pagination-controls (pageChange)="pendingPage = $event"></pagination-controls>

    
        </div>
        <div *ngIf="!AllpendingData">
          <app-emptycart></app-emptycart>
                  </div>
        <!-- <div *ngIf="!AllpendingData">
          <div class="h1  d-flex align-items-center justify-content-center text-muted" style="height: 400px;">{{pendingOrderError}}</div>
        </div> -->

      </div>

      <!-- completed Transactions -->
      <div *ngSwitchCase="'completed2'" id="no-tables" id="no-tables">
        
          <div *ngIf="completeOrderData">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <!-- <th>Inspection Date</th> -->
                  <th>Completion Date</th>
                  <th scope="col" (click)="sort('id')">
                    Order ID&nbsp;<i class="fa fa-sort"></i>
                  </th>
                  <th scope="col">Issue</th>
  
  
                  <th scope="col">Location</th>

                  <th scope="col">Status</th>

                </tr>
              </thead>
              <tbody >
                <tr
                  *ngFor="
                    let data of completeOrderData | paginate: { itemsPerPage: 20, currentPage: completePage}
                  "
                >
                  <td data-title="Date">{{ data.date }}</td>
                  <!-- <td data-title="inspection Date">{{ data.inspectionDate }}</td> -->
                  <td data-title="Completion Date">{{data.completionDate}}</td>
                  <td data-title="Order ID">{{data.uniqueId}}</td>
                  <td data-title="Issue">{{ data.issue }}</td>
                  
  
                  <td data-title="Location">{{ data.propertyAddress }}</td>
                  <!-- <td data-title="Artisan's Name">{{data.artisanName}}</td> -->
                  <td data-title="Action">Completed</td>
   </tr>
  
                
              </tbody>
            </table>
  
            <pagination-controls (pageChange)="completePage = $event"></pagination-controls>
          </div>
  
      

          <div *ngIf="!completeOrderData">
            <app-emptycart></app-emptycart>
                    </div>
    </div>

      <!-- cancel orders -->
      <div *ngSwitchCase="'completed3'" id="no-tables" id="no-tables">

        <div *ngIf="cancelOrderData">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th>Inspection Date</th>
                <th>Completion Date</th>
                <th scope="col" (click)="sort('id')">
                  Order ID&nbsp;<i class="fa fa-sort"></i>
                </th>
                <th scope="col">Issue</th>


               


                <th scope="col">Location</th>

                <th scope="col">Status</th>

              </tr>
            </thead>
            <tbody >
              <tr
                *ngFor="
                  let data of completeOrderData | paginate: { itemsPerPage: 20, currentPage: completePage}
                "
              >
                <td data-title="Date">{{ data.date }}</td>
                <td data-title="inspection Date">{{ data.inspectionDate }}</td>
                <td data-title="Completion Date"></td>
                <td data-title="Order ID">{{data.uniqueId}}</td>
                <td data-title="Issue">{{ data.issue }}</td>
                

                <td data-title="Location">{{ data.propertyAddress }}</td>
                <!-- <td data-title="Artisan's Name">{{data.artisanName}}</td> -->
                <td data-title="Action">Canceled</td>
 </tr>

              
            </tbody>
          </table>

          <pagination-controls (pageChange)="completePage = $event"></pagination-controls>
        </div>

        <div *ngIf="!cancelOrderData">
<app-emptycart></app-emptycart>
        </div>


      </div>

      <!-- invoice creation -->
      <div *ngSwitchCase="'completed6'">
        <!-- <app-invoice [check]="check"></app-invoice> -->
      </div>
    </div>
  </section>
</div>

<!-- modal for invoice -->
<ng-template #templateNested>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Quote</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <nav class="navbar navbar-expand-sm navbar-dark bg-primary">
      <a class="navbar-brand" >INVOICE</a>
    </nav>
    
    <div class="container-fluid pt-2" style="margin-bottom: 250px">
    
          <div class="card border-secondary">
            <div class="card-body">
              <h4 class="card-title">Invoice Details</h4>
             
              <div class="row">
    
                <div class="col-md-8">
                  
                  <div class="form-group">
                    <label for="date">Invoice Date</label>
                    <input type="date" class="form-control" name="invoiceDate" id="date" [(ngModel)]="InvoiceObject.invoiceDate"  #name1="ngModel"   required>
    
                   
                  </div>
                </div>
                <div class="col-md-4">
                  
    
                  <div class="form-group">
                    <label for="TAmount">Total Amount</label>
    
    
                    <input  type="text" class="form-control"[(ngModel)]="InvoiceObject.invoiceTotal" name="invoiceTotal" id="invoiceTotal" [ngModel]="getInvoiceTotalAmount()" disabled >
    
                  </div>
                </div>
    
              </div>
              
            </div>
          </div>
          <div class="card border-secondary mt-2 mb-5">
            <div class="card-body">
              <h4 class="card-title d-flex justify-content-between">Items Details 
               
                <button type="button" class="btn btn-info" (click)="addItem()">Add Input</button></h4>
    
    
                <div *ngFor="let item of invoice.items; let i = index">
                  <h3>Item {{ i + 1 }}</h3>              <div class="row" >
              
    
                    <div class="col-md-3">

                      <label for="mainProductName">Main Product Name:</label>
                      <select class="form-select" aria-label="Default select example" id="mainProductName" [(ngModel)]="item.mainProduct.name" placeholder="Main Product Name" (change)="onMainProductSelected(item, i)">
                        <option value="">Select a product</option>
                        <option *ngFor="let product of suggestedProducts" [value]="product.name">{{ product.name }} {{ product.name }} {{ product.name }} {{ product.name }}</option>
                      </select>
                    </div>
       
                <div class="col-md-3">
                  <label for="mainProductPrice">Main Product Price:</label>
                  <input type="number" class="form-control" id="mainProductPrice" [(ngModel)]="item.mainProduct.price"  placeholder="Main Product Price" >
                </div>
                <div class="col-md-3">
                  
                 
                  <label for="mainProductQuantity">Main Product Quantity:</label>
                  <input type="number" class="form-control" id="mainProductQuantity" type="number" [(ngModel)]="item.mainProduct.quantity" placeholder="Main Product Quantity">
                  
    
                </div>
                <div class="col-md-2">
                  
                  <label for="mainProductTotal">Main Total:</label>
                  <input type="number" class="form-control" id="mainProductTotal" type="number" [(ngModel)]="item.mainProduct.total" placeholder="Main Product Total" [ngModel]="updateTotalInItemsArray(item.mainProduct)">
                
                </div>

                <div class="col-md-3">
                  <label for="suggestedProductOneName">Suggested Product One</label>
                  <select class="form-select" aria-label="Default select example" id="suggestedProductOneName" name="suggestedProductOneName{{i}}" [(ngModel)]="item.suggestedProductOne.name" placeholder="Main Product Name" (change)="onSuggestedItem1Selected(item, i)">
                    <option value="">Select a product</option>
                    <option *ngFor="let product of suggestedProducts" [value]="product.name">{{ product.name }}</option>
                  </select>
                </div>
              
                <div class="col-md-3">
                  <label for="suggestedProductOnePrice">Suggested Product One Price:</label>
                  <input class="form-control" id="suggestedProductOnePrice" type="number" [(ngModel)]="item.suggestedProductOne.price" placeholder="Suggested Product One Price">
                </div>
              
                <div class="col-md-3">
                  <label for="suggestedProductOneQuantity">Suggested Product One Quantity:</label>
                  <input class="form-control" id="suggestedProductOneQuantity" type="number" [(ngModel)]="item.suggestedProductOne.quantity" placeholder="Suggested Product One Quantity">
                </div>
              
                <div class="col-md-3">
                  <label for="suggestedProductOneTotal">Suggested Product One Total:</label>
                  <input class="form-control" id="suggestedProductOneTotal" type="number" [(ngModel)]="item.suggestedProductOne.total" placeholder="Suggested Product One Total" [ngModel]="updateTotalInItemsArray(item.suggestedProductOne)">
                </div>
              
                  <!-- Repeat the same for suggestedProductTwo -->
              
                  <div class="col-md-3">
                    <label for="suggestedProductTwoName">Suggested Product Two:</label>
                    <select class="form-select" aria-label="Default select example" id="suggestedProductTwoName" [(ngModel)]="item.suggestedProductTwo.name" placeholder="Main Product Name" (change)="onSuggestedItem2Selected(item, i)">
                      <option class="form-select" aria-label="Default select example" value="">Select a product</option>
                      <option *ngFor="let product of suggestedProducts" [value]="product.name">{{ product.name }}</option>
                    </select>
                  </div>
                
                  <div class="col-md-3">
                    <label for="suggestedProductTwoPrice">Main Product Price:</label>
                    <input class="form-control" id="suggestedProductTwoPrice" type="number" [(ngModel)]="item.suggestedProductTwo.price" placeholder="Main Product Price">
                  </div>
                
                  <div class="col-md-3">
                    <label for="suggestedProductTwoQuantity">Main Product Quantity:</label>
                    <input class="form-control" id="suggestedProductTwoQuantity" type="number" [(ngModel)]="item.suggestedProductTwo.quantity" placeholder="Main Product Quantity">
                  </div>
                
                  <div class="col-md-3">
                    <label for="suggestedProductTwoTotal">Main Product Total:</label>
                    <input class="form-control" id="suggestedProductTwoTotal" type="number" [(ngModel)]="item.suggestedProductTwo.total" placeholder="Main Product Total" [ngModel]="updateTotalInItemsArray(item.suggestedProductTwo)">
                  </div>
              
    
                <div class="col-md-1" *ngIf="i!=0">
                  <button class="btn btn-danger" style="margin-top:30px" (click)="removeRow(i)">-</button>
                </div>
    
    
    
               </div>
   
              </div>
              
    
              <div class="form-floating mt-2">
                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea" [(ngModel)]="invoice.jobDescription"   required ></textarea>
                <label for="floatingTextarea">Description of Job to be done</label>
              </div>

              <div class="col-md-3">
                <h4>Service Charge:</h4><input class="form-control" type="text" name="artisanCharge" [(ngModel)]="invoice.artisanCharge" required="">
              </div>
              
    
    
              </div>
              <div style="color: #dc3545;">{{message2}}</div>
              <div style="color: #dc3545;">{{message}}</div>
            </div>
  
    
    <!--  [hidden]="!invoiceinformation.valid" [disabled]="!invoiceBody.valid"-->
    <button class="btn btn-danger  btn-lg me-2" (click)="closeModal()" >Cancel</button>

          <button type="button" name="" class="btn btn-info btn-lg" (click)="sendInvoice();"   >Send Quote</button>
    
    
    
    </div>

   
  </div>
</ng-template>


<!-- modal to view order -->
<ng-template #template2>
  <div class="modal-header">
    <h4 class="modal-title pull-left">ORDER DETAILS</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body" >

    <div><span class="me-2">  Order Date : </span><span class="h6">{{orderDate}}</span></div>

    <!-- !-- <button mat-button matRipple class="purple-500 fuse-white-fg mr-12"; (click)="imageSource()">Display</button> --> 
    <div class="d-flex" *ngFor="let data of orderfiles, let i = index"> 
      <img class="image-fluid" style="height: 100px; width:100px;" src="data:image/png;base64,{{data}}" *ngIf="hidden" />
    
    </div>      
    <div><span class="me-2">Pictures : </span><span class="h6"><button  class="btn btn-info my-2" (click)="imageSource()">View Pictures</button></span></div>
    
    

<div><span class="me-2">Has Invoice? : </span><span class="h6">{{orderById.hasInvoice}}</span></div>

<div><span class="me-2"> Order ID : </span><span class="h6">{{orderById.id}}</span></div>

<div><span class="me-2">Inspection Date : </span><span class="h6">{{orderById.inspectionDate}}</span></div>

<div><span class="me-2">Job Description : </span><span class="h6">{{orderById.issue}}</span></div>

<div><span class="me-2">Order Name : </span><span class="h6">{{orderById.name}}</span></div>


<div><span class="me-2"> Order Phone Number : </span><span class="h6" *ngIf="orderById.action  === 3 || orderById.action === 2 || orderById.action === 4">{{orderById.phoneNumber}}</span></div>

<div><span class="me-2"> Address : </span><span class="h6">{{orderById.propertyAddress}}</span></div>


<div class="mt-3"><span> Customer's Email : </span><span class="h6">{{orderById.customerEmail}}</span></div>

<div><span class="me-2"> Customer's Name : </span><span class="h6">{{orderById.customerName}}</span></div>

<div><span class="me-2">Customer's Phone Number : </span><span class="h6" *ngIf="orderById.action  === 3 || orderById.action === 2 || orderById.action === 4">{{orderById.customerPhoneNumber}}</span></div>

  </div>
  <button class="btn btn-danger me-2" (click)="modalRef?.hide()" >Cancel</button>

</ng-template>

<!-- <pre class="card card-block card-header">{{message}}</pre> -->
<!-- <ng-template #template3>
  <div class="modal-body text-center">
    <p>Kindly note the commission rate below on Artisans Service charge</p>
    <button type="button" class="btn btn-info" (click)=" openModal(template); onEdit(filteredOrderData)" >Proceed</button>
    <button type="button" class="btn btn-danger" (click)="decline()" >Decline</button>
  </div>
</ng-template> -->

<ng-template #template3>
  <div class="modal-body text-center">
    <p>Kindly note the commission rate below on Artisans Service charge</p>
    <div class="d-flex align-align-center justify-content-between ms-2 mt-2">
      <div>&#8358;5000 and Below</div>
      <div class="me-5">10%</div>
    </div>
    <div class="d-flex align-align-center justify-content-between ms-2 mt-2">
      <div>Above &#8358;5000 </div>
      <div class="me-5">20%</div>
    </div>

    <!-- <table>
      
      <tbody>
        <tr>
          <td>&#8358;5000 and Below</td>
          <td>10%</td>
        </tr>
        <tr>
          <td>Above &#8358;5000</td>
          <td>30%</td>
        </tr>
      </tbody>

    </table> -->
   <div class="d-flex float-end mt-3">
    <button type="button" class="btn btn-danger me-3" (click)="decline()" >Decline</button>
    <!-- <button type="button" class="btn btn-info" (click)=" openModal(template); decline() " >Proceed</button> -->
   </div>

  </div>
</ng-template>


<ng-template #template4>
  <div class="modal-header">
    <div class="modal-title pull-left h2">Commission Rate</div>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4>Kindly note the commission rate below on Artisans Service charge</h4>

    <table class="table">
     <thead>
      <tr>
        <th scope="col">Service Charge Amount(&#8358;)</th>
        <th scope="col"> Rate(%)</th>
      </tr>
     </thead>
     <tbody>
      <tr>
        <!-- <th scope="row"></th> -->
        <td >&#8358;5000 and Below</td>
        <td>10%</td>
      </tr>
      <tr>
        <!-- <th scope="row"></th> -->
        <td>Above &#8358;5000</td>
        <td>20%</td>
      </tr>
     </tbody>
    </table>
    <!-- <div class="d-flex align-align-center justify-content-between ms-2 mt-2">
      <div>&#8358;5000 and Below</div>
      <div class="me-5">10%</div>
    </div>
    <div class="d-flex align-align-center justify-content-between ms-2 mt-2">
      <div>Above &#8358;5000 </div>
      <div class="me-5">20%</div>
    </div> -->
    <br />
    <button type="button" class="btn btn-info me-2" (click)="openModal2(templateNested)">Agree</button>
    <button type="button" class="btn btn-danger" (click)="closeModal(1)">Decline</button>
    <!-- <button type="button" class="btn btn-primary" (click)="closeModal()">Close all modal</button> -->
  </div>
</ng-template>
 
<!-- <ng-template #templateNested>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Second modal</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef2?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    This is nested modal.<br />
    <button *ngIf="modalRef" type="button" class="btn btn-danger" (click)="closeFirstModal()">Close first modal</button> -->
    <!-- <button type="button" class="btn btn-danger" (click)="closeModal(2)">Close self</button>
    <button type="button" class="btn btn-danger" (click)="closeModal()">Close all modal</button>
  </div>
</ng-template> -->