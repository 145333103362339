<div class="h2 m-4">Frequently Asked Questions</div>

<div class="m-4 py-4 ps-4 rounded" style="background-color: #3bb6d5">
  <div class="row">
    <!-- <div class="col-12  g-0"> -->
    <div
      class="col-12 text-light col-lg-6 pb-2 d-flex align-items-center justify-content-center"
    >
      <div class="fs-6 font-monospace">
        Get all the answers to the most frequently asked questions (FAQs)
        regarding some of our popular categories which include AC repairs, carpenter, electrical, plumbling and a whole lot more from
        premium brands as well as managing your account, payment, vouchers and
        much, much more.
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <img src="/assets/images/questions.webp" alt="" class="img-fluid" />
    </div>
    <!-- </div> -->
  </div>
</div>


<app-zippy title="Who is an Artisan on Essential Artisan?" > 
    <p>
      An Artisan on Essential Artisan is an entity, individual or company who has the human, tool, professional service strategies and competence to provide service in any of the service categories on Essential Artisan.
      </p>
    </app-zippy> 
<app-zippy title="Can I be an Artisan without a Registered Company?"> 
    <p>
      Yes. In as much as you meet all pre-qualification criteria. Nevertheless, Essential Artisan will motivate you to get your company registered during the first six months on the platform.
      </p>
    </app-zippy> 
<app-zippy title="
What are Service Categories on Essential Artisan?
"> 
    <p>
      The Service Category is a list of services offered by Artisans on Essential Artisan and it includes: Air Conditioning, Carpentry, Plumbing and Electrical.      </p>
    </app-zippy> 
<app-zippy title="Can I register to provide service in more than one service category?"> 
    <p>
      Yes. However, you must have been pre-screened for the category. On the condition that, you have not been pre-screened, ensure that you inform our team who will pre-screen and add you to more categories after you have been successfully screened.    </p>
    </app-zippy> 
<app-zippy title="Do I need to pay any registration fee?"> 
    <p>
      No. All registration on Essential Artisan is free.      </p>
    </app-zippy> 
<app-zippy title="How do you guarantee security and impersonation?"> 
    <p>
      There will be an onboarding and security check process for all Artisans on Essential Artisans before registration on the website. Also, during service request the artisan’s image is what the customer receives when an artisan is being assigned to them. When the artisan arrives at the job site, the customer has a duty to ensure it is the same.      </p>
    </app-zippy> 
<app-zippy title="Can cash payment be made to the Artisan?"> 
    <p>
      No. All transactions can only be made to Essential Artisan Bank account details that will be provided in the generated invoice during the service request.      </p>
    </app-zippy> 
<app-zippy title="How does the transaction works?"> 
    <p>
      The customer selects the Artisan and date of inspection. The artisan goes for the inspection and then accesses the issue. If materials need to be bought, the artisan quote for the job through the app. The quote goes to the Service Manager at Essential Artisan and the customer. Once the customer agrees with the quote, the sum is paid to Essential Artisan’s account. If no material is needed to fix the issue, the artisan gets the issue fixed and the customer is charged a fee which will also be paid to Essential Artisan’s account.      </p>
    </app-zippy> 
