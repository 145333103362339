export class userProfileModel{
    id: string = "";
    firstName: string ="";
    lastName: string ="";
    email: string ="";
    mobilenumber: number = 0 ;
    AltnmbNr: number = 0 ;
    name: string ="";
    propertyAddress: string =""
    inspectionDate: string =""
    issue: string =""
    phoneNumber: string='';
  Address: string='';
  state: string='';
  city: string='';
  service: string='';  
  artisanId: number=0
  userId: number=0
  orderId: number= 0
  controls: any;
  accountNumber:any;
  bankCode:any;
  bankName:any
    
}