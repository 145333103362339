<section class="pt-3">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row mb-4">
        <div class="col-lg-8 mx-auto text-center">
          <!-- <h2 class="fs-1">Artisans Within Your Location</h2> -->
          <p class="mb-0">Choose from thousand of available Artisans</p>
        </div>
      </div>

      <!-- Tabs content START -->
      <div class="tab-content" id="course-pills-tabContent">
        <!-- Content START -->
        <div
          class="tab-pane fade show active"
          id="course-pills-tabs-1"
          role="tabpanel"
          aria-labelledby="course-pills-tab-1"
        >
          <div class="row g-4">
            <!-- Card item START -->
            <div class="col-6 col-lg-4 col-xl-3 cursor" #FRIDGE_FREEZER_REPAIR name="FRIDGE/FREEZER REPAIR" id="FRIDGE/FREEZER REPAIR" (click)="this.data.sendClickEvent(FRIDGE_FREEZER_REPAIR.id)" routerLink="/available artisan">
              <div class="card shadow h-100">
                <!-- Image -->
                <img
                  src="assets/images/Fridge 1.jpg"
                  class="card-img-top h-50 img-fluid"
                  alt="Essential-Artisans-Electrical"
                />
                <!-- Card body -->
                <div class="card-body pb-0">
                  <!-- Title -->
                  <h5 class="card-title fw-normal">
                    <a class="text-decoration-none text-info text-uppercase"> FRIDGE/FREEZER REPAIR</a>
                  </h5>
                  <p class="mb-2 text-truncate-2">
                    Get all fridge and freezer repair
                  </p>
                  <!-- Rating star -->
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="far fa-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item ms-2 h6 fw-light mb-0">
                      4.0/5.0
                    </li>
                  </ul>
                </div>
                <!-- Card footer -->
                <div class="card-footer pt-0 pb-3">
                  <hr />
                  <div>
                    See All Fridge/Freezer Repair
                    <span><i class="bi bi-arrow-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card item END -->

            <!-- Card item START -->
            <div class="col-6 col-sm-6 col-lg-4 col-xl-3 cursor" #Barber name="Barber" id="Barber" (click)="this.data.sendClickEvent(Barber.id)" routerLink="/available artisan" >
              <div class="card shadow h-100">
                <!-- Image -->
                <img
                  src="assets/images/barber 1.jpg"
                  class="card-img-top h-50 img-fluid"
                  alt="Essential-AC-Repair"
                />
                <div class="card-body pb-0">
                  <!-- Title -->
                  <h5 class="card-title fw-normal">
                    <a class="text-decoration-none text-info text-uppercase"> Barber</a>
                  </h5>
                  <p class="mb-2 text-truncate-2">
                    Get all your AC repair fixed
                  </p>
                  <!-- Rating star -->
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-half-alt-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item ms-2 h6 fw-light mb-0">
                      4.5/5.0
                    </li>
                  </ul>
                </div>
                <!-- Card footer -->
                <div class="card-footer pt-0 pb-3">
                  <hr />
                  <div>
                    See All Barber
                    <span><i class="bi bi-arrow-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card item END -->

          

          
            <!-- Card item START -->
           
            <!-- Card item END -->
            <!-- Card item START -->
            <div class=" col-6 col-sm-6 col-lg-4 col-xl-3 cursor" >
              <div class="card shadow h-100" #Plumbing name="Plumbing" routerLink="/available artisan" id="Plumbing" (click)="this.data.sendClickEvent(Plumbing.id)">
                <!-- Image -->
                <img
                  src="assets/images/plumbing.png"
                  class="card-img-top h-50"
                  alt="Essential-Plumbing"
                />
                <div class="card-body pb-0">
                  <!-- Badge and favorite -->

                  <!-- Title -->
                  <h5 class="card-title fw-normal">
                    <a class="text-decoration-none text-info text-uppercase"> PLUMBING</a>
                  </h5>
                  <p class="mb-2 text-truncate-2">
                    Get all your plumbing work done
                  </p>
                  <!-- Rating star -->
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="far fa-star text-warning"></i>
                    </li>
                    <li class="list-inline-item ms-2 h6 fw-light mb-0">
                      4.0/5.0
                    </li>
                  </ul>
                </div>
                <!-- Card footer -->
                <div class="card-footer pt-0 pb-3">
                  <hr />
                  <div>
                    See All Plumbers
                    <span><i class="bi bi-arrow-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card item END -->
           
            <!-- Card item START -->
            <div class=" col-6 col-sm-6 col-lg-4 col-xl-3 cursor" >
              <div class="card shadow h-100" #Catering_Service name="Catering Service" routerLink="/available artisan" id="Catering Service" (click)="this.data.sendClickEvent(Catering_Service.id)">
                <!-- Image -->
                <img
                  src="assets/images/catering 2.jpg"
                  class="card-img-top h-50"
                  alt="Essential-Plumbing"
                />
                <div class="card-body pb-0">
                  <!-- Badge and favorite -->

                  <!-- Title -->
                  <h5 class="card-title fw-normal">
                    <a class="text-decoration-none text-info text-uppercase"> Catering Service</a>
                  </h5>
                  <p class="mb-2 text-truncate-2">
                    Get all your Catering Service done
                  </p>
                  <!-- Rating star -->
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="far fa-star text-warning"></i>
                    </li>
                    <li class="list-inline-item ms-2 h6 fw-light mb-0">
                      4.0/5.0
                    </li>
                  </ul>
                </div>
                <!-- Card footer -->
                <div class="card-footer pt-0 pb-3">
                  <hr />
                  <div>
                    See All Catering Services
                    <span><i class="bi bi-arrow-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card item END -->
           
            <!-- Card item START -->
            <div class=" col-6 col-sm-6 col-lg-4 col-xl-3 cursor" >
              <div class="card shadow h-100" #Photographer name="Photographer" routerLink="/available artisan" id="Photographer" (click)="this.data.sendClickEvent(Photographer.id)">
                <!-- Image -->
                <img
                  src="assets/images/Photographer 2.jpg"
                  class="card-img-top h-50"
                  alt="Essential-Plumbing"
                />
                <div class="card-body pb-0">
                  <!-- Badge and favorite -->

                  <!-- Title -->
                  <h5 class="card-title fw-normal">
                    <a class="text-decoration-none text-info text-uppercase"> Photographer</a>
                  </h5>
                  <p class="mb-2 text-truncate-2">
                    <!-- Get all your p work done -->
                  </p>
                  <!-- Rating star -->
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="far fa-star text-warning"></i>
                    </li>
                    <li class="list-inline-item ms-2 h6 fw-light mb-0">
                      4.0/5.0
                    </li>
                  </ul>
                </div>
                <!-- Card footer -->
                <div class="card-footer pt-0 pb-3">
                  <hr />
                  <div>
                    See All Photographers
                    <span><i class="bi bi-arrow-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card item END -->
           
            <!-- Card item START -->
            <div class=" col-6 col-sm-6 col-lg-4 col-xl-3 cursor" >
              <div class="card shadow h-100" #Cleaning_Service name="Cleaning Service" routerLink="/available artisan" id="Cleaning Service" (click)="this.data.sendClickEvent(Cleaning_Service.id)">
                <!-- Image -->
                <img
                  src="assets/images/cleaning service 2.jpg"
                  class="card-img-top h-50"
                  alt="Essential-Plumbing"
                />
                <div class="card-body pb-0">
                  <!-- Badge and favorite -->

                  <!-- Title -->
                  <h5 class="card-title fw-normal">
                    <a class="text-decoration-none text-info text-uppercase"> Cleaning Service</a>
                  </h5>
                  <p class="mb-2 text-truncate-2">
                    Get all your Cleaning work done
                  </p>
                  <!-- Rating star -->
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="far fa-star text-warning"></i>
                    </li>
                    <li class="list-inline-item ms-2 h6 fw-light mb-0">
                      4.0/5.0
                    </li>
                  </ul>
                </div>
                <!-- Card footer -->
                <div class="card-footer pt-0 pb-3">
                  <hr />
                  <div>
                    See All Plumbers
                    <span><i class="bi bi-arrow-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card item END -->
           
            <!-- Card item START -->
            <div class=" col-6 col-sm-6 col-lg-4 col-xl-3 cursor" >
              <div class="card shadow h-100" #Fumigation_and_pest_control name="Fumigation and pest control" routerLink="/available artisan" id="Fumigation and pest control" (click)="this.data.sendClickEvent(Fumigation_and_pest_control.id)">
                <!-- Image -->
                <img
                  src="assets/images/cleaning service 1.jpg"
                  class="card-img-top h-50"
                  alt="Essential-Plumbing"
                />
                <div class="card-body pb-0">
                  <!-- Badge and favorite -->

                  <!-- Title -->
                  <h5 class="card-title fw-normal">
                    <a class="text-decoration-none text-info text-uppercase"> Fumigation and pest control</a>
                  </h5>
                  <p class="mb-2 text-truncate-2">
                    Get all your technical work done
                  </p>
                  <!-- Rating star -->
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="bi bi-star-fill text-warning"></i>
                    </li>
                    <li class="list-inline-item me-0 small">
                      <i class="far fa-star text-warning"></i>
                    </li>
                    <li class="list-inline-item ms-2 h6 fw-light mb-0">
                      4.0/5.0
                    </li>
                  </ul>
                </div>
                <!-- Card footer -->
                <div class="card-footer pt-0 pb-3">
                  <hr />
                  <div>
                    See All Plumbers
                    <span><i class="bi bi-arrow-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card item END -->
        
        