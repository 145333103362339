<main>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <!-- Image -->
          <img src="assets/images/error404.svg" class="h-200px h-md-400px mb-4" alt="">
          <!-- Title -->
          <div class="display-1 text-danger h1 fw-bold mb-0">404</div>
          <!-- Subtitle -->
          <div class="h1">Oh no, something went wrong!</div>
          <!-- info -->
          <p class="mb-4">Either something went wrong or this page doesn't exist anymore.</p>
          <!-- Button -->
          <a href="index.html" class="btn btn-info mb-0">Take me to Homepage</a>
        </div>
      </div>
    </div>
  </section>
  </main>