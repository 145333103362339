<div class="row pt-5 reset d-flex align-items-center p-4  justify-content-center " >
  
    <div class="mt-4 d-flex align-items-center p-4  justify-content-center flex-column col-md-4 s border border-dark border-2 rounded-3" >
      <h3>Reset Password</h3>
      <form [formGroup]="resetForm" (ngSubmit)="onSubmit(this.resetForm.value)">
        <div class="form-group">
          <label for="resetPasswordEmail" >Enter new Password</label><br>
          <mat-form-field class="example-full-width mb-1" appearance="fill">
            <input type="password" matInput formControlName="password" placeholder="Ex. pat@example.com">
          
            
            <mat-error *ngIf="resetForm.hasError('required', 'password')">
             Password is <strong>required</strong>
            </mat-error>
          </mat-form-field><br>
          <label for="resetPasswordEmail" >Confirm Password</label><br>
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="password" matInput formControlName="confirmPassword" placeholder="Ex. pat@example.com">
         
            
            <mat-error *ngIf="resetForm.hasError('required', 'confirmPassword')">
              confirmPassword is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <p style="color: red;">{{message}}</p>
        <button
          type="submit"
          [disabled]="resetForm.invalid"
          class="btn btn-info my-4"
        >
          Reset Password
        </button>
       
      </form>
    </div>
  </div>
  