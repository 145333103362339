<div class="">
  <div class="h-25 w-100 d-flex align-items-center">
    <div class="container fixed-top">
      <marquee class="text-info" style="font-size: 22px;
      padding-top: 5px;height: 40px;">Sign up and Enjoy up to 40% Discount on all Orders for all Service Category. Free Inspection on your First Order.</marquee>
      <!-- <div class="scrolling-text text-info" [@scroll]="state" (@scroll.done)="scrollDone()">Sign up and Enjoy up to 40% Discount on all Orders for all Service Category. Free Inspection on your First Order.</div> -->
    </div>
  </div>
  <nav class="navbar navbar-expand-lg bg-light  ">
    <div class="container-fluid ">
      <a class="navbar-brand cursor" routerLink="/" 
      data-bs-target="#navbarTogglerDemo02"
      aria-controls="navbarTogglerDemo02"
      aria-expanded="false"
      aria-label="Toggle navigation"
        ><img alt="Essential-Artisans-logo" src="/assets/images/logos.png" style="width: 200px" alt=""
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
      <mat-icon>menu</mat-icon>
      </button>
      <div class="collapse navbar-collapse " id="navbarTogglerDemo02">
        <ul class="navbar-nav ms-auto mb-4 mb-lg-0">
       

          <li class="hover nav-item cursor bottom-0" *ngIf="modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS'"> 
           
            <!-- <div class=""> -->
              <a class="  nav-link  my-2 my-lg-0 me-4 navItem cursor"  *ngIf="modalPwaPlatform === 'ANDROID'" (click)="addToHomeScreen()" style="font-size: 20px; font-weight: 400; " 
            ><i class=" bi bi-download me-2"></i>DOWNLOAD</a
          >
              <a class="  nav-link  my-2 my-lg-0 me-4 navItem cursor"  *ngIf="modalPwaPlatform === 'IOS'" style="font-size: 20px; font-weight: 400; " 
            ><img src="https://res.cloudinary.com/rodrigokamada/image/upload/v1641089482/Blog/angular-pwa/safari_action_button_38x50.png" class="ios-menu m-0" />
            DOWNLOAD
            <!-- <i class="bi bi-plus-square"></i> -->
            </a
          >
              <!-- <button type="button" class="btn-close position-absolute top-0 end-0 m-1" aria-label="Close" (click)="closePwa()"></button> -->
              <!-- Android -->
              <!-- <div >
                DOWNLOAD
              </div> -->
              <!-- iOS with Safari -->
              <!-- <div *ngIf="modalPwaPlatform === 'IOS'">
                <img src="https://res.cloudinary.com/rodrigokamada/image/upload/v1641089482/Blog/angular-pwa/safari_action_button_38x50.png" class="ios-menu m-0" />
                DOWNLOAD
                <i class="bi bi-plus-square"></i>
              </div> -->
           
            <!-- </div> -->
        </li>
          <!-- <li class="hover nav-item "   data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation" *ngIf="!loginApi.loggedIn()">
            <a class="hover nav-link navItem my-2 my-lg-0 me-4 cursor"  routerLink="/register" style="font-size: 20px; font-weight: 400;"
              ><i class="fa-brands fa-hire-a-helper me-2"></i>HIRE ARTISANS</a
            >
          </li> -->
          <li class="hover nav-item "   data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation">
            <a class="hover nav-link navItem my-2 my-lg-0 me-4 cursor "  routerLink="/signup" style="font-size: 20px; font-weight: 400;"
              ><i class="fa-solid fa-money-bill-trend-up me-2  "></i>BECOME AN ARTISAN</a
            >
          </li>
          <li>
            
          </li>
          <li class=" hover nav-item "   data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation">
            <a class="  nav-link  my-2 my-lg-0 me-4 navItem cursor"  routerLink="/helpcenter" style="font-size: 20px; font-weight: 400; " 
              ><i class=" fa-solid fa-circle-question me-2"></i>HELP</a
            >
          </li>
          <li class="hover nav-item my-2 my-lg-0"   data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation">
            <a class=" nav-link  me-4 cursor navItem" routerLink="/aboutus" style="font-size: 20px; font-weight: 400;"
              ><i class="fa-solid fa-address-card"></i> ABOUT</a
            >
          </li>
          <li class="hover nav-item my-2 my-lg-0"   data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation" *ngIf="loginApi.loggedIn() && this.displayAdmin" routerLink="/Admin">
            <a class="nav-link  me-4 cursor navItem"  style="font-size: 20px; font-weight: 400;"
              ><i class="fa-solid fa-user"></i> My PROFILE</a
            >
          </li>
          <li class="hover nav-item my-2 my-lg-0"   data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation" *ngIf="loginApi.loggedIn() && this.displayUser "  routerLink="/User"
          (click)="onClick(loginApi.loggedinUser.id)">
            <a class="nav-link  me-4 cursor navItem"  style="font-size: 20px; font-weight: 400;"
              ><i class="fa-solid fa-user"></i> My PROFILE</a
            >
          </li>
          <li class="hover nav-item my-2 my-lg-0"   data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation" *ngIf="loginApi.loggedIn() && this.displayArtisan"  
          routerLink="/artisanprofile/transactions"
          (click)="onClick(loginApi.loggedinUser.id)">
            <a class="nav-link  me-4 cursor navItem"  style="font-size: 20px; font-weight: 400;"
              ><i class="fa-solid fa-user"></i> My PROFILE</a
            >
          </li>
          <li class="hover nav-item my-2 my-lg-0"   data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"   
          routerLink="/market place"
          >
            <a class="nav-link  me-4 cursor navItem"  style="font-size: 20px; font-weight: 400;"
              ><i class="fa-solid fa-shop"></i> MARKET PlACE</a
            >
          </li>
  
          <li class="nav-item my-2 my-lg-0 navItem">
            <div
              class=" nav-link d-flex justify-content-center text-white cursor"
              style="
                background-color: #033d94;
                width: 120px;
                height: 40px;
                border: none;
                border-radius: 7px;
                font-size: 20px;
                
              "
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              routerLink="/signin"
              *ngIf="!loginApi.loggedIn()"
            >
              SIGN IN
            </div>
          </li>
  
          <li class="nav-item my-2 my-lg-0 navItem">
            <div
              class="nav-link d-flex justify-content-center text-white cursor"
              style="
                background-color:#033d94;
                width: 120px;
                height: 40px;
                border: none;
                border-radius: 7px;
                font-size: 20px;
                
              "
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              *ngIf="loginApi.loggedIn()"
              (click)="Logout()"
            >
              LOG OUT
            </div>
          </li>
    
        </ul>
      </div>
    </div>
  </nav>
  
  
  
  
  

  <router-outlet class="mt-5"></router-outlet>


  
</div>

<ngx-http-loader  [opacity]="0.5"  [subTitle]="'ESSENTIAL ARTISANS'" [loader]="ngxloader.ELLIPSIS" [iconColor]="'white'" [backdropBackgroundColor]="'black'">
</ngx-http-loader>




  