
<section class="w-100 d-flex justify-content-center align-items-center" style="background-color: #f4f4f8;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-6">
        <div class="card" style="border-radius: 1rem;">
          <div class="row ">
           
            <div class="col-md-12 col-lg-12 d-flex align-items-center">
              <div class="card-body p-4 p-lg-5 text-black">

                <form  [formGroup]="signupForm" (ngSubmit)="onSubmit(this.signupForm.value)">

                  <!-- <div class="d-flex align-items-center mb-3 pb-1">
                    <img class="w-50" src="/assets/images/logos.png" alt="">
                  </div> -->

                  <div class="fw-normal h3 mb-3 pb-3 text-info" style="letter-spacing: 1px">
                    SIGN UP AS A USER 
                  </div>
                  <div class="form-group form-outline mb-2">
                    <label class="form-label text-info" for="">First Name <span style="color: red;">*</span></label>

                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      formControlName="firstName"
                      class="form-control form-control-lg mb-1"
                      [ngClass]="{ 'is-invalid': submitted && signupFormControl['firstName'].errors }"
                    />
                    <span style="color: #dc3545;"
                    *ngIf="(signupFormControl['firstName'].touched || submitted) && signupFormControl['firstName'].errors?.['required']">
                    First Name is required
                </span>
                
                   
                  </div>

                  <div class="form-group form-outline my-3">
                    <label class="form-label text-info" for="form2Example27">Last Name <span style="color: red;">*</span></label>

                    <input
                      type="text"
                      id=""
                      formControlName="lastName"
                      class="form-control form-control-lg mb-1"
                      [ngClass]="{ 'is-invalid': submitted && signupFormControl['lastName'].errors }"

                    />

                    <span
                    style="color: #dc3545;"
                      *ngIf="(signupFormControl['lastName'].touched || submitted) && signupFormControl['lastName'].errors?.['required']"
                    >
                      LastName is required
                    </span>
                  </div>

                  <div class="form-group form-outline mb-2">
                    <label class="form-label text-info" for="">Email <span style="color: red;">*</span></label>

                    <input
                      type="email"
                      id=""
                      formControlName="email"
                      class="form-control form-control-lg mb-1"
                      [ngClass]="{ 'is-invalid': submitted && signupFormControl['email'].errors }"

                    />

                    <span
                    style="color: #dc3545;"
                      *ngIf="(signupFormControl['email'].touched || submitted) && signupFormControl['email'].errors?.['required']"
                    >
                      Email is required
                    </span>
                    <span
                    style="color: #dc3545;"
                      *ngIf="signupFormControl['email'].touched && signupFormControl['email'].errors?.['email']"
                    >
                      Enter a valid email address
                    </span>
                  </div>

                  <div class="form-group form-outline mb-2">
                    <label class="form-label text-info" for="">Phone <span style="color: red;">*</span></label>
                    <input
                      type="text"
                      id=""
                      formControlName="PhoneNumber"
                      class="form-control form-control-lg mb-1"
                      [ngClass]="{ 'is-invalid': submitted && signupFormControl['PhoneNumber'].errors }"

                    />

                    <span
                    style="color: #dc3545;"
                      *ngIf="(signupFormControl['PhoneNumber'].touched || submitted) && signupFormControl['PhoneNumber'].errors?.['required']"
                    >
                      Phone Number is required
                    </span>
                    
                  </div>

                  
                  <div class="form-group input-group-lg form-outline mb-2" (click)="getState()">
                    <label class="form-label mt-2 text-info" for="">State Location <span style="color: red;">*</span></label>
                    <select class="form-select" aria-label="Default select example" formControlName="state" (change)="onChangeState($event.target)">
                      <!-- <option value="" selected disabled>Select State</option> -->
                      <option *ngFor="let data of state2" [ngValue]="data.sate">{{data.sate}}</option>
                      
                    </select>
                    <span
                    style="color: #dc3545;"
                      *ngIf="(signupFormControl['state'].touched || submitted) && signupFormControl['state'].errors?.['required']"
                    >
                      State is required
                    </span>
                     
                    </div>
  
                    <div class="form-group input-group-lg form-outline mb-2"   >
                      <label class="form-label mt-2 text-info" for="">LGA Location <span style="color: red;">*</span></label>
                      <select class="form-select" aria-label="Default select example" formControlName="city" (change)="onChangeCity($event.target)">
                        <!-- <option selected value="">Select LGA</option> -->
                        <option *ngFor="let data of city2" value="{{data}}">{{data}}</option>
                        
                      </select>
                      <span
                      style="color: #dc3545;"
                        *ngIf="(signupFormControl['city'].touched || submitted) && signupFormControl['city'].errors?.['required']"
                      >
                        LGA is required
                      </span>
                      </div>

                  <div class="form-group form-outline mb-2">
                    <label for="country" class="form-label text-info">Address <span style="color: red;">*</span></label>
                    <input
                      type="text"
                      id=""
                      formControlName="address"
                      class="form-control form-control-lg mb-1"
                      [ngClass]="{ 'is-invalid': submitted && signupFormControl['address'].errors }"

                    />

                    <span
                    style="color: #dc3545;"
                      *ngIf="(signupFormControl['address'].touched || submitted) && signupFormControl['address'].errors?.['required']"
                    >
                      Address is required
                    </span>
                   
                  </div>

                  <div class="form-group form-outline mb-2">
                    <label for="country" class="form-label text-info">Password <span style="color: red;">*</span></label>

                    <mat-form-field appearance="outline" class="w-100">
                     <input matInput [type]="hide ? 'password' : 'text'"  class="ps-1 fs-5"
                      id="mypassword"
                      formControlName="password"
                      name="password">
                      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                    </mat-form-field>
                    <span
                    style="color: #dc3545;"
                    *ngIf="(signupFormControl['password'].touched || submitted) && signupFormControl['password'].errors?.['required']"
                  >
                    Password is required
                  </span>
              
              
                  </div>

<div>
  <span
  class=" h4 mb-1" role="alert" style="color: #dc3545;"
  *ngIf="signupForm.valid || submitted"
  
>
 {{ errorMessage }}
</span>
</div>
                  <div class="form-group pt-1 mb-2 ">
                    <button
                      class="btn btn-info btn-lg btn-block w-100"
                      type="submit"
                      
                    >
                      Sign Up
                    </button>
                  </div>

                  <div class="d-flex flex-column  align-items-center">
                  
                  <p class="mb-3 pb-lg-2 cursor" style="color: #393f81;" routerLink="/signin">Already have an account? Sign in here</p>
                  <a href="#!" class="small text-muted" routerLink="/ourpolicy">Privacy policy</a>
                  </div>
                </form>
                <!-- <button class="btn btn-success btn-lg btn-block" (click)="resendEmail()">Resend Link</button> -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


