<div class="d-flex dashboard pt-4">
  <div class="container-fluid">
    <!-- PROFILE CARDS 1 -->

    <div class="cards-6 section-gray">
      <div class="container-fluid w-100">
        <div class="5">Available Artisans within your Selected Category</div>

        <!-- <div class="col-12  col-md-6 mb-3 float-right">        <input type="text" placeholder="filter with jobDescription"  name="value" [(ngModel)]="value" (keyup)="Search($event.target) " >      
        </div> -->
        <div class=" w-100 d-flex justify-content-between  ">
         <div class="col-6 my-2">
          <input
          class="form-control"
          type="text"
          name="search"
          [(ngModel)]="searchText"
          autocomplete="off"
          placeholder="&#61442;  Start searching for Artisans within your location"
        />
         </div>
<div>          <button class="btn btn-info my-2">Select Category</button>
</div>        </div>

        <div class="row mt-4 cursor">
          <div
            class="col-md-4 mb-5 mb-md-none"
            *ngFor="
              let data of artisanData
                | filter : searchText
                | paginate
                  : {
                      id: 'listing_pagination',
                      itemsPerPage: 6,
                      currentPage: page,
                      totalItems: totalRecord
                    }
            "
          >
            <div
              class="card card-profile"
              (click)="onEdit(data); openModal(template)"
            >
              <div class="card-avatar">
                <a>
                  <img
                    class="img-fluid"
                    style="width: 200px; height: 200px; object-fit: fill"
                    *ngIf="data.profileImage"
                    src="data:image/png;base64,{{ data.profileImage }}"
                  />
                  <div class="img" *ngIf="!data.profileImage">
                    <ngx-avatar
                      size="130"
                      name="{{ data.firstName }} {{ data.lastName }}"
                    ></ngx-avatar>
                  </div>
                  <!-- <img class="img img-fluid " *ngIf="!data.profileImage" src="/assets/images/avatar1.webp" /> -->
                </a>
              </div>

              <div class="table">
                <h4 class="card-caption">
                  {{ data.name }}
                </h4>
                <p class="category text-muted h6 pt-3">
                  {{ data.firstName }} {{ data.lastName }}
                </p>

                <!-- <p class=" text-center mt-2" >
                  
                  {{data.profession}}

                </p> -->

                <div class="d-flex justify-content-between">
                  <div class="list-inline-item mt-2 small">
                    <i class="bi bi-geo-alt-fill"></i>{{ data.location }}
                  </div>
                </div>
                <!-- <ngx-star-rating [id]="data.rating" [(ngModel)]="data.rating" [disabled]="true"></ngx-star-rating> -->
              </div>
            </div>
            <span
              (click)="getArtisanReviews(data)"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <span *ngIf="data.rating === 5"
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ></span>
              <!-- when rating is 4 -->
              <span *ngIf="data.rating === 4"
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ></span>

              <!-- when rating is 3 -->

              <span *ngIf="data.rating === 3"
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ></span>

              <!-- when rating is 2 -->

              <span *ngIf="data.rating === 2"
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ></span>

              <!-- when rating is 1 -->

              <span *ngIf="data.rating === 1"
                ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ></span>
              <!-- rating zero -->
              <span *ngIf="data.rating === 0"
                ><i class="bi bi-star"></i><i class="bi bi-star"></i
                ><i class="bi bi-star"></i><i class="bi bi-star"></i
                ><i class="bi bi-star"></i
              ></span>
              ({{ data.reviewCount }} verified rating)</span
            >
          </div>
        </div>

        <div
          *ngIf="!artisanData"
          class="w-100 h-100 d-flex align-center justify-content-center"
        >
          No Artisan Within Your Location
        </div>
      </div>
    </div>
  </div>
</div>

<div></div>

<!-- modal for invoice -->
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Order</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!-- modal form -->
    <form
      [formGroup]="formValue"
      (ngSubmit)="onSubmitCheck(this.formValue.value)"
    >
      <div class="mb-1">
        <label for="exampleInputEmail1" class="form-label">Full Name</label>
        <input
          formControlName="Name"
          type="text"
          class="form-control"
          id="exampleInputText"
          aria-describedby="emailHelp"
          [ngClass]="{
            'is-invalid': submitted && orderFormControl['Name'].errors
          }"
        />
        <span
          style="color: #dc3545"
          *ngIf="(orderFormControl['Name'].touched || submitted) && orderFormControl['Name'].errors?.['required']"
        >
          Name Field is required
        </span>
      </div>

      <div class="mb-1">
        <label for="exampleInputPassword1" class="form-label">Address</label>
        <input
          formControlName="PropertyAddress"
          type="text"
          class="form-control"
          id="exampleInputPassword1"
          [ngClass]="{
            'is-invalid':
              submitted && orderFormControl['PropertyAddress'].errors
          }"
        />
        <span
          style="color: #dc3545"
          *ngIf="(orderFormControl['PropertyAddress'].touched || submitted) && orderFormControl['PropertyAddress'].errors?.['required']"
        >
          Address is required
        </span>
      </div>

      <div class="mb-1">
        <label for="exampleInputPassword1" class="form-label"
          >Phone Number</label
        >
        <input
          formControlName="PhoneNumber"
          type="number"
          class="form-control"
          [ngClass]="{
            'is-invalid': submitted && orderFormControl['PhoneNumber'].errors
          }"
        />
        <span
          style="color: #dc3545"
          *ngIf="(orderFormControl['PhoneNumber'].touched || submitted) && orderFormControl['PhoneNumber'].errors?.['required']"
        >
          Phone Number is required
        </span>
      </div>
      <!-- <div class="mb-1">
        <label for="exampleInputPassword1" class="form-label"
          >Alternative Number</label
        >
        <input
          formControlName="AlternateNumber"
          type="number"
          class="form-control"
        />
      </div> -->

      <!-- <div>
      <output *ngIf=successResponse class="success"><b>{{successResponse}}</b></output>
  </div> -->

      <div class="my-2">
        <label for="exampleInputPassword1" class="form-label"
          >Description</label
        >
        <input
          formControlName="Issue"
          type="textarea"
          class="form-control"
          id="exampleInputPassword1"
          [ngClass]="{
            'is-invalid': submitted && orderFormControl['Issue'].errors
          }"
        />
        <span
          style="color: #dc3545"
          *ngIf="(orderFormControl['Issue'].touched || submitted) && orderFormControl['Issue'].errors?.['required']"
        >
          Job Description is required
        </span>
      </div>
      <div class="mt-4">
        <label for="exampleInputPassword1" class="form-label me-2"
          >Inspection Date and Time</label
        >
        <input
          formControlName="InspectionDateAndTime"
          type="datetime-local"
          [(ngModel)]="value"
          #input
          (change)="onChange(input.value)"
          placeholder="
          dd/mm/yyyy, --:--
          "
        />
        <br />
        <span
          style="color: #dc3545"
          *ngIf="(orderFormControl['InspectionDateAndTime'].touched || submitted) && orderFormControl['InspectionDateAndTime'].errors?.['required']"
        >
          Inspection Date and Time is required
        </span>
        <br />
        <div class="my-2">
          <span>Support formats are .jpg .gif .png </span><br />
          <span>Pictures may not exceed 2MB</span>
        </div>
      </div>

      <label class="btn btn-default">
        <input
          type="file"
          multiple
          (change)="selectFiles($event)"
          formControlName="files"
        />
      </label>

      <div class="mt-4">
        <!-- <input type="file" formControlName="Files" (change)="uploadfile($event)" multiple> -->
      </div>
      <div class="h5" style="color: red">{{ message }} <span class="text-info " style="cursor: pointer;" *ngIf="message === 'Insufficient wallet balance'" routerLink="/User/Wallet" (click)="modalRef?.hide()"> Fund wallet</span></div>

      <button class="btn btn-danger mt-3 me-2" id="ModelId" type="submit" (click)="modalRef?.hide()">Cancel</button>
      <button class="btn btn-info mt-3" id="ModelId" type="submit">Send</button>
    </form>
  </div>
</ng-template>

<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          Verified Customer Feedback
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
                  <div *ngIf="!artisanReviews" class="d-flex justify-content-center">No review fo this Artisan</div>

        <div class="card p-2" *ngFor="let reviews of artisanReviews">
          <div>{{ reviews.customerFirstName }}</div>

          <div class="d-flex justify-content-center py-2 ps-3">
            {{ reviews.customerComment }}
          </div>
          <!-- <div *ngIf="!reviews" class="d-flex justify-content-center">No review fo this Artisan</div> -->
          <!-- <div class="d-flex float-end">{{reviews.rating}}</div> -->
          <span class="d-flex float-end">
            <span *ngIf="reviews.rating === 5"
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
            ></span>
            <!-- when rating is 4 -->
            <span *ngIf="reviews.rating === 4"
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
            ></span>

            <!-- when rating is 3 -->

            <span *ngIf="reviews.rating === 3"
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
            ></span>

            <!-- when rating is 2 -->

            <span *ngIf="reviews.rating === 2"
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
            ></span>

            <!-- when rating is 1 -->

            <span *ngIf="reviews.rating === 1"
              ><i class="bi bi-star-fill" style="color: #f7b01f"></i
            ></span>
            <!-- rating zero -->
            <span *ngIf="reviews.rating === 0"
              ><i class="bi bi-star"></i><i class="bi bi-star"></i
              ><i class="bi bi-star"></i><i class="bi bi-star"></i
              ><i class="bi bi-star"></i
            ></span>
            ({{ reviews.reviewCount }} verified rating)</span
          >
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
