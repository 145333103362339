<div class="">

  <!-- hero -->
  <section id="hero" class="hero d-flex align-items-center">
    <div class="container-fluid">
      <div class=" mb-5">
        <p class="text-light h1" style="width: 18rem">
          10+ Artisans at your fingertip
        </p>
        <p class="text-light lead Montserrat sans-serif mt-5">
          Ease the stress of finding artisans
        </p>
      </div>
      <div class="col-12  rounded d-flex mt-5">
        <!-- <div class="card col-6 col-md-4 bg-light bg-opacity-50 d-flex p-1 pe-4">
          <div class="mb-2 h4">Location</div>
          <div
            class="h5 text-light"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            data-bs-whatever="@mdo"
          >
            Search your location
            <i class="fa-solid fa-location-dot float-end"></i>
          </div>
        </div> -->
        <!-- <div class="card col-4  d-flex p-3 bg-light bg-opacity-50 "> -->
        <div class="dropdown card col-6 col-md-4 d-flex p-1 bg-light bg-opacity-50 pe-4">

          <div class="mb-2 h4">HIRE ARTISANS</div>
          <!-- <i class="fa-brands fa-hire-a-helper me-2"></i> -->

            <div class="btn btn-secondary dropdown-toggle d-flex flex-start"  data-bs-toggle="dropdown" aria-expanded="false">
              Available Services
            </div>
            <ul class="dropdown-menu w-100">
              <li><a class="dropdown-item" #AC_Repair name="AC Repair" id="AC Repair" (click)="this.data.sendClickEvent(AC_Repair.id)" routerLink="/available artisan">AC Technician</a></li>
              <li><a class="dropdown-item" #Carpentry name="Carpentry" id="Carpentry" routerLink="/available artisan" (click)="this.data.sendClickEvent(Carpentry.id)">Carpenter</a></li>
              <li><a class="dropdown-item" #Electrician name="Electrician" id="Electrician" (click)="this.data.sendClickEvent(Electrician.id)" routerLink="/available artisan">Electrician</a></li>
              <li><a class="dropdown-item" #Plumbing name="Plumbing" routerLink="/available artisan" id="Plumbing" (click)="this.data.sendClickEvent(Plumbing.id)">Plumbing</a></li>
              <li><a class="dropdown-item" #Cleaning_Service name="Cleaning Service" routerLink="/available artisan" id="Clearning Service" (click)="this.data.sendClickEvent(Cleaning_Service.id)">Cleaning Service</a></li>
            </ul>
         
          <!-- <select class="form-select" aria-label="Default select example" (change)="onChangeService($event.target)" >
            <option selected>Select Service</option>
            <option  *ngFor="let data of serviceData " value="{{ data.name }}" ()="this.data.sendClickEvent(hope3)" routerLink="/available artisan">{{data.name}}</option>
           
          </select>  -->

         
        </div>
        <!-- </div> -->
      </div>
    </div>
  </section>
  <!-- End Hero -->

  <!-- counter -->

  <section class="py-0 py-xl-5 pt-3">
    <div class="container">
      <div class="row g-4">
        <!-- Counter item -->
        <div class=" col-6 col-sm-6 col-xl-3">
          <div
            class="d-flex justify-content-center align-items-center p-4 bg-primary bg-opacity-10 rounded-3"
          >
            <span class="display-6 lh-1 text-warning mb-0"
              ><i class="bi bi-tv"></i
            ></span>
            <div class="ms-4 h6 fw-normal mb-0">
              <div class="d-flex">
                <h5
                  class="purecounter mb-0 fw-bold"
                  data-purecounter-start="0"
                  data-purecounter-end="10"
                  data-purecounter-delay="200"
                ></h5>
                <span class="mb-0 h5">50+</span>
              </div>
              <p class="mb-0">Reviews</p>
            </div>
          </div>
        </div>
        <!-- Counter item -->
        <div class="col-6 col-sm-6 col-xl-3">
          <div
            class="d-flex justify-content-center align-items-center p-4 bg-primary bg-opacity-10 rounded-3"
          >
            <span class="display-6 lh-1 text-primary mb-0"
              ><i class="fa-solid fa-users"></i
            ></span>
            <div class="ms-4 h6 fw-normal mb-0">
              <div class="d-flex">
                <h5
                  class="purecounter mb-0 fw-bold"
                  data-purecounter-start="0"
                  data-purecounter-end="200"
                  data-purecounter-delay="200"
                ></h5>
                <span class="mb-0 h5">50+</span>
              </div>
              <p class="mb-0">Users</p>
            </div>
          </div>
        </div>
        <!-- Counter item -->
        <div class="col-6 col-sm-6 col-xl-3">
          <div
            class="d-flex justify-content-center align-items-center p-4 bg-primary bg-opacity-10 rounded-3"
          >
            <span class="display-6 lh-1 text-success mb-0"
              ><i class="fa-solid fa-circle-check"></i
            ></span>
            <div class="ms-4 h6 fw-normal mb-0">
              <div class="d-flex">
                <h5
                  class="purecounter mb-0 fw-bold"
                  data-purecounter-start="0"
                  data-purecounter-end="60"
                  data-purecounter-delay="200"
                ></h5>
                <span class="mb-0 h5">100+</span>
              </div>
              <p class="mb-0">Job Done</p>
            </div>
          </div>
        </div>
        <!-- Counter item -->
        <div class="col-6 col-sm-6 col-xl-3">
          <div
            class="d-flex justify-content-center align-items-center p-4 bg-primary bg-opacity-10 rounded-3"
          >
            <span class="display-6 lh-1 text-info mb-0"
              ><i class="bi bi-patch-check-fill"></i
            ></span>
            <div class="ms-2 h6 fw-normal mb-0">
              <div class="d-flex">
                <h5
                  class="purecounter mb-0 fw-bold"
                  data-purecounter-start="0"
                  data-purecounter-end="6"
                  data-purecounter-delay="300"
                >
                  <!-- 0 -->
                </h5>
                <span class="mb-0 h5">10+</span>
              </div>
              <p class="mb-0">Certified Artisans</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>




 

  <!-- artisans within location -->
  <section class="pt-3" #scroll id="scrollable">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row mb-4">
        <div class="col-lg-8 mx-auto text-center">
          <h2 class="fs-1">Artisans Within Your Location</h2>
          <p class="mb-0">Choose from thousand of available Artisans</p>
        </div>
      </div>
   

      <!-- Tabs content START -->
      <div class="row g-4">
        <div class="col-6 col-sm-6 col-lg-4 col-xl-3 cursor" *ngFor="let card of serviceData" >
          <div class="card shadow h-100"  routerLink="/available artisan"  (click)="checkthis(card)" >
            <!-- Image -->
            <img
              [src]="card.imageURL"
    
              class="card-img-top h-50 img-fluid"
              alt="Essential-AC-Repair"
            />
            <div class="card-body pb-0">
              <!-- Title -->
              <h5 class="card-title fw-normal">
                <a class="text-decoration-none text-info">{{card.name}}</a>
              </h5>
              <p class="mb-2 text-truncate-2">
                Get all your {{card.name}} fixed
              </p>
              <!-- Rating star -->
              <ul class="list-inline mb-0">
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-fill text-warning"></i>
                </li>
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-fill text-warning"></i>
                </li>
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-fill text-warning"></i>
                </li>
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-fill text-warning"></i>
                </li>
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-half-alt-fill text-warning"></i>
                </li>
                <li class="list-inline-item ms-2 h6 fw-light mb-0">
                  4.5/5.0
                </li>
              </ul>
            </div>
            <!-- Card footer -->
            <div class="card-footer pt-0 pb-3">
              <hr />
              <div>
                See All {{card.name}}
                <span><i class="bi bi-arrow-right"></i></span>
              </div>
            </div>
            
          </div>
           
        </div>
        <div class="col-6 col-sm-6 col-lg-4 col-xl-3 cursor"  >
          <div class="card shadow h-100" (click)="checkToggle()">
            <!-- Image -->
            <img
              src="assets/images/viewmore.jpg"
              class="card-img-top h-50"
              alt="Essential-Carpentry"
            />
            <div class="card-body pb-0">
              <!-- Title -->
              <h5 class="card-title fw-normal">
                <a class="text-decoration-none text-info"> View More</a>
              </h5>
              <p class="mb-2 text-truncate-2">
                Get all your carpentry work done
              </p>
              <!-- Rating star -->
              <ul class="list-inline mb-0">
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-fill text-warning"></i>
                </li>
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-fill text-warning"></i>
                </li>
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-fill text-warning"></i>
                </li>
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-fill text-warning"></i>
                </li>
                <li class="list-inline-item me-0 small">
                  <i class="bi bi-star-half-alt text-warning"></i>
                </li>
                <li class="list-inline-item ms-2 h6 fw-light mb-0">
                  4.5/5.0
                </li>
              </ul>
            </div>
            <!-- Card footer -->
            <div class="card-footer pt-0 pb-3">
              <hr />
              <div>
                See All Carpenters
                <span><i class="bi bi-arrow-right"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4" *ngIf="displaySubcategories == true"  >
          <div class="row mb-4">
            <!-- <div class="card col-6 col-sm-6 col-lg-4 col-xl-3"> -->
              <app-categories></app-categories>
            <!-- </div> -->
          </div>
         
        </div>
        
          
        
      </div>
      <!-- Tabsn cotent END -->
    </div>
  </section>


 

  <!-- what people says -->
  <section class="container-fluid pt-md-2 mt-5 bg-primary bg-opacity-10 shadow">
    <div class="row">
      <div class="col-md-5">
        <div class="card h-100 border-0 overflow-hidden px-md-4 background">
          <span
            class="bg-gradient-primary position-absolute top-0 start-0 w-100 h-100 opacity-10 d-none d-md-block"
          ></span>
          <div
            class="card-body d-flex flex-column align-items-center justify-content-center position-relative zindex-2 p-0 pb-2 p-lg-4"
          >
            <p class="h2 text-center text-md-start p-lg-4">
              What People Say About Us
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div
          class="card border-0 shadow-sm p-4 p-xxl-5 bg-primary bg-opacity-10"
        >
          <div class="d-flex justify-content-between pb-4 mb-2">
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner text-center">
                <div class="carousel-item active">
                  <p class="fs-lg mb-0">
                    First class service. I asked them to replace an electric shower which they did quickly and efficiently. The Artisan man who did the job is a credit to his company.
                  </p>
                  <div
                    class="card-footer border-0 d-flex align-items-center w-100 pb-2 bg-primary bg-opacity-10"
                  >
                  
                    <div class="ps-3">
                      <h3 class="fw-semibold lh-base mb-0 me-2">
                        Mrs Ibukun Abimbola
                      </h3>
                      <span>Ikoyi, Lagos</span>
                     
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <p class="fs-lg mb-0">
                    They promised a great service and they delivered it. Really friendly and professional help throughout the process of buying and installing an EV charger. Genuine pride in making the work look as good as it can but perhaps more importantly, on making it safe. Would (and will) happily use in future.
                  </p>
                  <div
                    class="card-footer border-0 d-flex align-items-center w-100 pb-2"
                  >
                  
                    <div class="ps-3 " >
                      <h3 class="fw-semibold lh-base mb-0 me-2 "> Miss Mikka Erem</h3>
                      <span>Victoria Island, Lagos</span>
                      
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <p class="fs-lg mb-0">
                  We are very happy with the quality of the work done, which was to replace an original 1962 fuse box and a separate newer one with an all metal 17th Edition consumer unit, plus carrying out the required safety check. All done very tidily in a day.
                  </p>
                  <div
                    class="card-footer border-0 d-flex align-items-center w-100 my-2"
                  >
                  
                    <div class="ps-3 ">
                      <h3 class="fw-semibold lh-base mb-0 me-e"> Mr Samuel Arinze</h3>
                     <span>Lekki, Lagos</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End of what people says about us -->

  <!-- ======= About Section ======= -->
  <section class="container mt-5 "  >
    <div class="row partner g-0" style="background-image: linear-gradient(#c6e1ee, #ddeff7); overflow: hidden;">
      <div
      class="col-md-6 d-flex flex-column align-items-center justify-content-center position-relative zindex-2 p-0 pb-2 p-lg-4 "
      
    >
    <div class="h2 mt-1 mt-md-4 "> Earn extra money </div>
    <p class="h6 mt-2">Set your own schedule, be your own boss</p>
  <div class="d-flex">
    <button class=" h5 text-light mt-4 " style="background-color: #1daeff; border: none;" routerLink="/signup">Become An Artisans</button>
    <!-- <button class=" h6 text-light mt-4 ms-2" style="background-color: #1daeff; border: none;" routerLink="">Become A Vendor</button> -->
  </div>
    </div>
      <div class="col-md-6 partner d-none d-md-block  ">
        <img src="assets/images/partnerimage.jpg" alt="Become-an-artisan" class="img-fluid img w-100"  />
      </div>
   
    </div>
  </section>

  <!-- End About Section -->

  <!-- supported brands -->
  <section class="container mt-2 pt-3 pt-lg-5 pb-5 d-flex align-items-center flex-column">
    <div class="text-center h2 pb-md-2">Trusted by Leading Companies</div>

    <div class="row w-50 d-flex align-items-center ">
      <div
        class="col-12 card-group justify-content-between align-items-center d-flex"
      >
        <div class="col-2 col-sm-2 col-lg-2 col-xl-2">
          <!-- Card -->
          <img
            src="assets/images/all-smiles.png"
            alt=""
            class="img-fluid rounded-circle"
          />
          <!-- <div class="card mb-4 rounded box h-100">
        <img src="assets/images/all-smiles.png" alt="" class="img-fluid rounded-circle">
      </div> -->
        </div>
        <div class="col-2 col-sm-2 col-lg-2 col-xl-2">
          <!-- Card -->
          <img
            src="assets/images/flash-gas.jpg"
            alt=""
            class="img-fluid rounded-circle"
          />
          <!-- <div class="card mb-4 rounded h-100 box">
      <img src="" alt="" class="img-fluid">
    </div> -->
        </div>
        <div class="col-2 col-sm-2 col-lg-2 col-xl-2">
          <!-- Card -->
          <img
            src="assets/images/appletrees.png"
            alt=""
            class="img-fluid rounded-circle"
          />
          <!-- <div class="card mb-4 rounded h-100 box">
        <img src="" alt="" class="img-fluid">
    </div> -->
        </div>
        <div class="col-2 col-sm-2 col-lg-2 col-xl-2">
          <!-- Card -->
          <img
            src="assets/images/kwodra.png"
            alt=""
            class="border border-5 border-dark img-fluid rounded-circle"
          />
          <!-- <div class="card mb-4 rounded h-100 box">
        <img src="" alt="" class="img-fluid">
    </div> -->
        </div>
      </div>
    </div>
  </section>

 
  
  


  <app-footer></app-footer>

</div>

<!-- modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New message</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
     <form action="" [formGroup]="searchForm" (ngSubmit)="onLocationFilter()">
      <div class="d-flex flex-grow-1">

      <div class="form-group form-outline mb-4">
                   
        <select class="form-select" aria-label="Default select example" formControlName="state" (change)="onChangeState($event.target)">
          <option value="" selected disabled>Select State</option>
          <option *ngFor="let data of state2" [ngValue]="data.sate">{{data.sate}}</option>
          
        </select>
        <div>  <label class="form-label mt-2" for="">State Location</label></div>
        </div>

        <div class="form-group input-group-lg form-outline mb-4"   >
          <select class="form-select" aria-label="Default select example" formControlName="city" (change)="onChangeCity($event.target)">
            <option selected value="">Select LGA</option>
            <option *ngFor="let data of city2" value="{{data}}">{{data}}</option>
            
          </select>
          <div>  <label class="form-label mt-2" for="">LGA Location</label></div>
          </div>
      <button type="submit" class="btn btn-info h-50 ms-2" >search</button>
    </div>

     </form>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <!-- <button type="button" class="btn btn-primary">Send message</button> -->
      </div>
    </div>
  </div>
</div>
