<div class="container " >
    <div class="ms-0">
        <div class="h5 class-title">my transactions</div>
    </div>
    <div class="center d-flex flex-column align-items-center">
        <img class="w-50" src="/assets/images/emptycart.png" alt="">
        <h3>Your card is empty</h3>
        <h4>No available transaction </h4>
        
    </div>
</div>
