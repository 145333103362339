<div class="m-4">
 
            
<div title="Shipping Details"> </div>

<div class="zippy">
  <div class="zippy-heading"
  [class.expanded]="isExpanded"
  (click)="toggle()"
  >
    {{ title }}
    <span class="bi"
    [ngClass]="{
        'bi-dash' : isExpanded,
        'bi-plus' : !isExpanded
    }"
    ></span> 
  </div>
  <div *ngIf="isExpanded" class="zippy-body">
    <ng-content></ng-content>
    
  </div>
</div>

</div>
