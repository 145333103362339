<div class="modal-header">    
  <h4 class="modal-title" id="modal-basic-title"> Confirmation</h4> 
</div>     
<div class="modal-body">  
  <p>A new version available, please update before continuing, update now ?</p>     
</div>    
<div class="modal-footer"> 
  <button type="button" class="btn btn-outline-dark"> Yes, Update</button>   
  <button type="button" class="btn btn-outline-dark" > Cancel</button>     
</div> 
  
  <!-- <app-progress [progress]="progress"></app-progress> -->
