<div class="row pt-5 password d-flex align-items-center p-4  justify-content-center">
  <div class="mt-4 d-flex align-items-center p-4  justify-content-center flex-column col-md-4 s border border-dark border-2 rounded-3">
    <h3>Reset Password</h3>
    <form [formGroup]="Form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="resetPasswordEmail" class="me-3 my-2">Account Email</label>
        <mat-form-field class="example-full-width" appearance="fill">
          <!-- <mat-label>Email</mat-label> -->
          <input type="email" matInput formControlName="email" placeholder="Ex. pat@example.com">
          <mat-error   *ngIf="
          Form.controls['email'].dirty &&
          Form.hasError('required', 'email')
        ">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="Form.hasError('required', 'email')">
            Email is <strong >required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <p>{{message}}</p>
      <button
        type="submit"
        [disabled]="Form.invalid"
        class="btn btn-info mt-4"
      >
        Reset Password
      </button>
     
    </form>
  </div>
</div>
