import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signuproute',
  templateUrl: './signuproute.component.html',
  styleUrls: ['./signuproute.component.css']
})
export class SignuprouteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
